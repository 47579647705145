import { ref } from 'vue';
import { defineStore } from 'pinia';

type SpeechResultCallback = (spokenText: string | null) => void;

// eslint-disable-next-line
export const useSpeechRecognitionStore = defineStore('speech', () => {

  const isListening = ref(false);
  // eslint-disable-next-line
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = ref();

  const startSpeechRecognition = (onResultCallback: SpeechResultCallback) => {
    recognition.value = new SpeechRecognition();
    recognition.value.lang = 'ko-KR';
    recognition.value.onstart = () => {
      console.log('Speech recognition started');
      isListening.value = true;
    };

    recognition.value.onend = () => {
      console.log('Speech recognition ended');
      isListening.value = false;
      onResultCallback(null);
    };

    recognition.value.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error);
    };

    recognition.value.onresult = (event: any) => {
      const confidenceThreshold = 0.5; // Adjust as needed
      const spokenText = event.results[0][0].transcript;
      const { confidence } = event.results[0][0];
      const { transcript } = event.results[0][0];
      if (confidence > confidenceThreshold) {
        console.log('spokenText:', spokenText);
        onResultCallback(transcript);
      } else {
        console.log('Speech confidence below threshold. Ignoring result.');
      }
      console.log('Spoken Text:', spokenText);
      console.log('Confidence:', confidence);
    };
    recognition.value.start();
  };

  const stopSpeechRecognition = () => {
    recognition.value?.stop();
    isListening.value = false;
  };

  return {
    isListening,
    startSpeechRecognition,
    stopSpeechRecognition,
  };
});
