import {
  ref,
  reactive,
} from 'vue';
import { defineStore } from 'pinia';
import {
  iUserRoom,
  iRoomMessage,
  iMessage,
} from '@interface/chat';
import { tabStore } from './storeAuth';
import { routeHelper } from '@utils';
import router from '@router';

export const useWebSocketStore = defineStore('socket', () => {
  const socket = ref();
  const intervalId = ref();
  const intervalMilliseconds = 60000;
  const connectionStatus = ref('Disconnected');
  const reconnectAttempt = ref(false);
  const reconnectInterval = 5000;
  const isInvited = ref(false);
  const tabData = tabStore();

  const invitingInfo = reactive({
    roomId: '',
    userId: '',
    nickName: '',
  });
  const currentRoomInfo = reactive<iUserRoom>({
    roomId: '',
    userId: '',
    nickName: '',
    isActive: false,
    profileUrl: '',
  });
  const currentRoomLastChat = ref('');
  const receiveTokenList = ref<any[]>([]);
  const sender = ref('');
  const WS_URL = 'wss://api.custom-voice.ggm.kr:9000/ws';
  const activeUserRooms = ref<iUserRoom[]>([]);
  const roomMessageList = ref<iRoomMessage>({});
  const waitingRoomId = ref('');
  const waitingRoomCounter = ref(0);

  const addRoomMessage = (roomId: string, msg: iMessage) => {
    if (!roomMessageList.value[roomId]) {
      roomMessageList.value[roomId] = [];
    }
    roomMessageList.value[roomId].push(msg);
    if (msg.sender !== sender.value && roomId === currentRoomInfo.roomId) { // 현재 채팅룸& 상대방의 chat인 경우
      currentRoomLastChat.value = msg.chat;
    }
  };

  const setCurrentRoomInfo = (roomId: string, userId: string, nickName: string, isActive: boolean, profileUrl: string) => {
    currentRoomInfo.roomId = roomId;
    currentRoomInfo.userId = userId;
    currentRoomInfo.nickName = nickName;
    currentRoomInfo.isActive = isActive;
    currentRoomInfo.profileUrl = profileUrl;
  };

  const joinChatRoom = (roomId: string) => {
    console.log('2️⃣ Start Join Chat Room', activeUserRooms.value);
    const room = activeUserRooms.value.find((r) => r.roomId === roomId && r.roomId !== '');
    console.log('room', room);
    if (!room) {
      console.log('Room not found', roomId);
      return;
    }
    setCurrentRoomInfo(room.roomId, room.userId, room.nickName, room.isActive, room.profileUrl);
    console.log(room, '3️⃣조인 챗 룸 다음에 room안에 들은 것');
  };

  const connectWebSocket = (userId: string) => {
    sender.value = userId;
    socket.value = new WebSocket(`${WS_URL}/${userId}`);
    socket.value.onopen = () => {
      console.log('[Websocket] open');
      connectionStatus.value = 'Connected';
      reconnectAttempt.value = false;
      // eslint-disable-next-line
      intervalId.value = setInterval(() => sendToken({
        event: 'ACTIVE_INFO',
        roomId: '',
        data: {},
      }), intervalMilliseconds);
    };

    socket.value.onmessage = (message: MessageEvent) => {
      console.log('[Websocket] get message:', message);
      const jsonData = JSON.parse(message.data);
      console.log('jsonData', jsonData);
      const goChatRoom = () => {
        if (jsonData.room_id) {
          joinChatRoom(jsonData.room_id);
          router.push({
            name: 'chatUser',
            params: { id: jsonData.room_id },
          });
        }
      };
      if (waitingRoomId.value === jsonData.room_id) {
        waitingRoomCounter.value += 1;
        if (waitingRoomCounter.value === 2) {
          setTimeout(() => {
            tabData.sideMenu = false;
            goChatRoom();
          }, 2000);
        }
      }
      receiveTokenList.value.push({
        sender: jsonData.sender,
        event: jsonData.event,
        room_id: jsonData.room_id,
        data: jsonData.data,
      });
      switch (jsonData.event) {
      case 'INVITE_ROOM':
        console.log('[Websocket] event : INVITE_ROOM');
        invitingInfo.roomId = jsonData.room_id;
        invitingInfo.userId = jsonData.data.user_id;
        invitingInfo.nickName = jsonData.data.nick_name;
        isInvited.value = true;
        break;
      case 'USER_JOIN':
        waitingRoomId.value = jsonData.room_id;
        console.log('[Websocket] event : USER_JOIN');
        addRoomMessage(jsonData.room_id, {
          sender: jsonData.sender,
          chat: jsonData.data.message,
          type: 'NOTICE',
        });
        break;
      case 'USER_LEFT':
        console.log('[Websocket] event : USER_LEFT');
        addRoomMessage(jsonData.room_id, {
          sender: jsonData.sender,
          chat: jsonData.data.message,
          type: 'NOTICE',
        });
        break;
      case 'USER_REJECT':
        console.log('[Websocket] event : USER_REJECT');
        addRoomMessage(jsonData.room_id, {
          sender: jsonData.sender,
          chat: jsonData.data.message,
          type: 'NOTICE',
        });
        break;
      case 'ACTIVE_INFO':
        console.log('[Websocket] event : ACTIVE_INFO');
        // Set Active User Rooms Data
        console.log(jsonData);
        activeUserRooms.value = [];
        jsonData.data.user_room_list.forEach((room: any) => {
          activeUserRooms.value.push({
            userId: room.user_id,
            nickName: room.nick_name,
            roomId: room.room_id,
            isActive: room.is_active,
            profileUrl: room.profile_url,
          });
          if (room && room.room_id !== undefined && room.room_id !== '' && room.room_id === currentRoomInfo.roomId) {
            console.log('current room info setting');
            setCurrentRoomInfo(room.room_id, room.user_id, room.nick_name, room.is_active, room.profile_url);
          }
        });
        console.log('activeUserRooms:', activeUserRooms.value);
        break;
      case 'INFO':
        console.log('[Websocket] event : INFO');
        console.log('Error message :', jsonData.data.message);
        break;
      case 'RECEIVE_CHAT':
        addRoomMessage(jsonData.room_id, {
          sender: jsonData.sender,
          chat: jsonData.data.chat,
          type: 'CHAT',
        });
        break;
      default:
        console.log('[Websocket] event : unknown event');
        break;
      }
    };

    const handleReconnect = () => {
      if (!reconnectAttempt.value) {
        reconnectAttempt.value = true;
        setTimeout(() => {
          // connectWebSocket(sender.value);
          socket.value = new WebSocket(`${WS_URL}/${userId}`);
        }, reconnectInterval);
      }
    };

    socket.value.onclose = () => {
      console.log('[Websocket] close');
      connectionStatus.value = 'Disconnected';
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
      handleReconnect();
    };

    socket.value.onerror = () => {
      console.log('[Websocket] error');
      connectionStatus.value = 'Error';
      socket.value.close();
    };
  };

  const setSender = (userId: string) => {
    sender.value = userId;
  };

  const sendToken = async (params: {event: string; roomId: string; data: any}) => {
    const data = {
      sender: sender.value,
      event: params.event,
      room_id: params.roomId,
      data: params.data,
    };
    console.log('send token Message: ', data);
    const jsonData = JSON.stringify(data);
    await socket.value.send(jsonData);
  };

  const disconnectWebSocket = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value);
    }
    if (connectionStatus.value === 'Connected') {
      socket.value.close();
    }
  };

  const resetWebSocketStore = () => {
    socket.value = undefined;
    intervalId.value = undefined;
    connectionStatus.value = 'Disconnected';
    reconnectAttempt.value = false;
    isInvited.value = false;
    invitingInfo.roomId = '';
    invitingInfo.userId = '';
    invitingInfo.nickName = '';
    currentRoomInfo.roomId = '';
    currentRoomInfo.userId = '';
    currentRoomInfo.nickName = '';
    currentRoomInfo.isActive = false;
    currentRoomLastChat.value = '';
    receiveTokenList.value = [];
    sender.value = '';
    activeUserRooms.value = [];
    roomMessageList.value = {};
  };

  return {
    resetWebSocketStore,
    sender,
    connectionStatus,
    invitingInfo,
    isInvited,
    receiveTokenList,
    activeUserRooms,
    roomMessageList,
    currentRoomInfo,
    currentRoomLastChat,
    addRoomMessage,
    connectWebSocket,
    setSender,
    joinChatRoom,
    sendToken,
    disconnectWebSocket,
  };
});

export default { useWebSocketStore };
