<template>
  <div
    v-if="currentRoomInfo.userId !== ''"
    class="wrap_screen"
  >
    <div
      class="mute"
      @click="turnMute"
    >
      <img
        v-if="isMute"
        src="@assets/speaker_mute.png"
      >
      <img
        v-else
        src="@assets/speaker_on.png"
      >
    </div>
    <!-- 상단 채팅 대상-->
    <div class="tabbar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu"
      />
      <div class="other_profile">
        <div>
          <img
            v-if="isAnimal(currentRoomInfo.profileUrl, ['fox', 'shark', 'tiger', 'koala', 'bear', 'unicorn', 'lion', 'dog'])"
            class="other_picture"
            :src="require(`@assets/${currentRoomInfo.profileUrl}`)"
            alt="Profile Picture"
          >
          <img
            v-else
            class="other_picture"
            src="@assets/emoji_nobody.png"
          >
        </div>
        <div class="other_profile_info">
          <div>{{ currentRoomInfo.nickName }}</div>
          <!-- <div class="other_additional_info">
            additional information
          </div> -->
        </div>
      </div>
    </div>
    <div
      ref="chatScreenRef"
      class="scroll"
    >
      <div class="wrap_chat_screen">
        <div class="room_info">
          <div>{{ currentRoomInfo.nickName }}와 대화를 시작해보세요</div>
        </div>
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'bubble': true,
            'other_bubble': message.sender !== myUserId,
            'my_bubble': message.sender === myUserId
          }"
        >
          {{ message.chat }}
        </div>
      </div>
    </div>
    <div class="wrap_input_screen">
      <div class="wrap_input">
        <textarea
          v-model="newMessage"
          class="input"
          placeholder="Message"
          @keypress.enter.exact.prevent="handleShiftEnterKey"
        />
        <div
          v-if="!isListening"
          class="button_rec"
          @click="startSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-microphone"
          />
        </div>
        <div
          v-if="isListening"
          class="button_rec"
          @click="stopSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-square"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="wrap_screen"
  >
    <!-- 상단 채팅 대상-->
    <div class="tabbar">
      <div class="other_profile">
        <div class="other_profile_info">
          <!-- <div class="other_additional_info">
            additional information
          </div> -->
        </div>
      </div>
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu"
      />
    </div>
    <div class="wrap_waiting_screen">
      <div class="room_info">
        <div>메세지를 보내려면 채팅을 선택하세요.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import {
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import { useWebSocketStore } from '../stores/storeWebSocket';
import { useSpeechRecognitionStore } from '../stores/storeSpeechRecognition';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { useAudioStore } from '../stores/storeAudio';
import { pushPage } from '@utils/route.helper';
import { errorType } from '@interface/error';
import { postSpeakerUser } from '@apis/voicechat';
import { postStreams } from '@apis/streams';

const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

// eslint-disable-next-line camelcase, arrow-body-style
const isAnimal = (profileUrl: string, animals: string[]) => {
  return profileUrl && animals.some((animal) => profileUrl.includes(animal));
};

const isMute = ref(false);
const turnMute = () => {
  isMute.value = !isMute.value;
};
const chatScreenRef = ref<HTMLElement | null>(null);
const scrollToBottom = () => {
  if (chatScreenRef.value) {
    chatScreenRef.value.scrollTop = chatScreenRef.value.scrollHeight;
  }
};
const client = useWebSocketStore();
const route = useRoute();
const roomId: string = route.query.id as string;
console.log('chatUser에 접속한 roomId(query string):', roomId);

// const otherMessage = ref('응! 알겠어~');
const messages = computed(() => client.roomMessageList[client.currentRoomInfo.roomId]);
const myUserId = computed(() => client.sender);
const currentRoomInfo = computed(() => client.currentRoomInfo);
const newMessage = ref<string>('');
const isSpeaking = ref(true);
const speakerBase64Result = ref('');

// speaker user
const speakerUser = async (base64Data: string) => {
  const params = { audio_base64_string: base64Data };
  try {
    const result = await postSpeakerUser(params);
    console.log(result.data.speaker_base64_string, '💛💛');
    speakerBase64Result.value = result.data.speaker_base64_string;
  } catch (error) {
    alert(error);
  }
};

// 메시지 보냄
const sendMessage = () => {
  console.log('sending messages', newMessage.value);
  console.log('currentRoomInfo', currentRoomInfo.value, 'gogogogogogo');
  if (!currentRoomInfo.value.isActive) {
    console.log('room is not active ', messages.value);
    return;
  }
  // console.log('message list', messages.value);
  const chat = newMessage.value.trim();
  console.log('chat', chat);
  newMessage.value = '';
  if (chat !== '') {
    client.sendToken({
      event: 'SEND_CHAT',
      roomId: currentRoomInfo.value.roomId,
      data: { chat },
    });

    setTimeout(() => {
      scrollToBottom();
    }, 600);
  }
};

const handleEnterKey = () => {
  sendMessage();
  newMessage.value = '';
};

const handleShiftEnterKey = (event: any) => {
  if (event.shiftKey) {
    // 쉬프트 키를 누르면서 엔터 키를 눌렀을 때
    newMessage.value += '\n'; // 또는 다른 줄바꿈 처리 방법을 사용할 수 있습니다.
  } else {
    // 쉬프트 키 없이 엔터 키만 눌렀을 때
    handleEnterKey();
  }
};

// SECTION -  ChatUser:STT
const speechRecognitionStore = useSpeechRecognitionStore();
const handleSpeechResult = (spokenText: string | null) => {
  if (spokenText) {
    newMessage.value = spokenText;
    sendMessage();
  }
};
const startSpeechListening = () => {
  speechRecognitionStore.startSpeechRecognition(handleSpeechResult);
};
const stopSpeechListening = () => {
  speechRecognitionStore.stopSpeechRecognition();
};
const isListening = computed(() => speechRecognitionStore.isListening);
// !SECTION - ChatUser:STT

// SECTION - ChatUser:TTS
const streamUser = async (quest: string, sender: string) => {
  const params = {
    quest,
    user_id: sender,
  };
  try {
    const audioStore = useAudioStore();
    console.log('call streamUser', '🩷🩷');
    postStreams('user', params.quest, params.user_id);
  } catch (error) {
    console.log(error);
  }
};

watch(
  () => client.currentRoomLastChat,
  (newValue, oldValue) => {
    console.log('watch :', newValue, oldValue);
    // 속성 값이 변경될 때 실행되는 로직
    if (isSpeaking.value && newValue && !isMute.value) {
      console.log('[quest] :', newValue, '[isSpeaking]:', isSpeaking.value);
      streamUser(newValue, client.currentRoomInfo.userId);
    }
  },
);
// !SECTION - ChatUser:TTS
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background: #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_bubble {
  margin-right: auto; /* Align to the left */
  background-color: #F7F8FB;
}

.my_bubble {
  margin-left: auto; /* Align to the right */
  background-color: #FFC107; /* Your desired background color for the current user */
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.room_info {
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.295);
  border-radius: 20px;
}

.bubble {
  display: flex;
  align-items: center;
  max-width: 800px;
  height: auto;
  padding: 10px;
  margin-top: 10px;
  color: #3E404A;
  word-break: break-all;
  border-radius: 30px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.input_chat {
  background: white;
}

.scroll {
  display: flex;
  align-items: start;
  justify-content: center;
  height: 80vh;
  overflow-y: scroll;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.my_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 12px;
  background-color: rgb(255, 108, 108);
  border-radius: 0.5rem;
}

.other_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 12px;
  background-color: rgb(255, 189, 69);
  border-radius: 0.5rem;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .input {
    width: 700px;
    height: 50px;
    max-height: 245px;
    padding: 15px;
    margin-right: 10px;
    font-size: 18px;
    resize: none;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 0.5rem;
  }

  .tabbar {
    position: fixed;
    top: 0;
    left: 350px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: calc(100% - 350px);
    height: 50px;
    padding: 0 20px;
    background: white;
    border-bottom: 1px solid #EAEAEA;
  }

  .wrap_chat_screen {
    display: grid;
    place-items: center;
    width: 700px;
    margin-top: 100px;
  }

  .wrap_waiting_screen {
    display: flex;
    place-items: center;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 350px);
    height: 100vh;
  }

  .mute {
    position: absolute;
    top: 100px;
    right: 50px;
    cursor: pointer;
    img {
      width: 70px;
    }
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .wrap_chat_screen {
    display: grid;
    place-items: center;
    width: 40vh;
    margin-top: 100px;
  }

  .desktop {
    display: none;
  }

  .tabbar {
    position: fixed;
    top: 0;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    background: white;
    border-bottom: 1px solid #EAEAEA;
  }

  .input {
    width: 300px;
    height: 50px;
    max-height: 245px;
    padding: 15px;
    margin-right: 10px;
    font-size: 18px;
    resize: none;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 0.5rem;
  }

  .wrap_waiting_screen {
    display: flex;
    place-items: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .mute {
    position: absolute;
    top: 50px;
    right: 20px;
    cursor: pointer;
    img {
      width: 50px;
    }
  }
}
</style>
