<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div>
        관리자에게 문의하기
      </div>
    </div>
    <div>
      <div class="wrap_chat_screen">
        <div class="form_box">
          <label for="name">아이디</label>
          <input
            name="name"
            :value="userData.userId ? userData.userId : ''"
            disabled
          >

          <label for="email">연락 받을 이메일</label>
          <input
            v-model="email"
            type="email"
            name="email"
          >

          <label for="message">문의 사항</label>
          <textarea
            v-model="message"
            name="message"
          />
          <div class="wrap_button">
            <div
              class="button_okay"
              type="submit"
              @click="postAskAdmin"
            >
              문의하기
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import type { SelectProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import {
  iAvatar,
  iUserRoom,
} from '@interface/chat';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { pushPage } from '@utils/route.helper';
import router from '@router';
import { errorType } from '@interface/error';
import {
  postAvatar,
  requestGetAvatarList,
  getAvatarDetail,
  deleteAvatar,
  postAsk,
} from '@apis/voicechat';

const userData = userStore();
const name = ref('');
const email = ref('');
const message = ref('');
const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

const postAskAdmin = async () => {
  const params = {
    name: name.value,
    email: email.value,
    message: message.value,
  };
  if (!name.value.trim()) {
    AntNotification.error({ message: '이름을 넣어주세요!' });
    return;
  }
  if (!email.value.trim()) {
    AntNotification.error({ message: '받을 이메일 주소를 넣어주세요!' });
    return;
  }
  if (!message.value.trim()) {
    AntNotification.error({ message: '메시지를 넣어주세요!' });
    return;
  }
  try {
    const result = await postAsk(params);
    console.log(result);
    AntNotification.success({ message: '문의를 전송하였습니다.' });
    name.value = '';
    email.value = '';
    message.value = '';
  } catch (error: any) {
    AntNotification.error({ message: error?.message });
  }
};
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  background-color: #EFEFF3;
}

.scroll {
  height: 90vh;
  overflow-y: scroll;
}

.contact_form {
  width: 400px;
  padding: 20px;
  margin: 50px auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border: 1px solid #DADCE0;
  border-radius: 10px;
}

textarea {
  height: 300px;
}

button {
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
  background-color: #4CAF50;
  border: none;
  border-radius: 3px;
}

button:hover {
  background-color: #45a049;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .form_box {
    width: 510px;
    padding: 20px;
    margin: 50px auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .wrap_chat_screen {
    display: grid;
    place-items: center;
    margin-top: 100px;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .form_box {
    width: 350px;
    padding: 20px;
    margin: 0 auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .wrap_chat_screen {
    display: grid;
    place-items: center;
    height: 100vh;
  }
}
</style>
