<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="tabbar">
      <div class="back">
        <font-awesome-icon
          class="chevron"
          icon="fa-solid fa-chevron-left"
        />
        <div>Back</div>
      </div>
      <div class="other_profile">
        채팅 커스텀 보이스
      </div>
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
      />
    </div>
    <div
      ref="chatScreenRef"
      class="chat_screen"
    >
      <div
        v-for="(message, index) in messages"
        :key="index"
      >
        <div
          class="wrap_icon_message"
          :class="{ 'other': message.from !== 'me', 'me': message.from === 'me' }"
        >
          <div :class="{ 'other_icon': message.from !== 'me', 'my_icon': message.from === 'me' }">
            <span v-if="message.from === 'me'">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                color="white"
                class="eyeqlp51 st-emotion-cache-fblp2m ex0cdmw0"
              >

                <path
                  fill="none"
                  d="M0 0h24v24H0V0z"
                />
                <!-- eslint-disable-next-line max-len -->
                <path d="M10.25 13a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM15 11.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm7 .25c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10zM10.66 4.12C12.06 6.44 14.6 8 17.5 8c.46 0 .91-.05 1.34-.12C17.44 5.56 14.9 4 12 4c-.46 0-.91.05-1.34.12zM4.42 9.47a8.046 8.046 0 003.66-4.44 8.046 8.046 0 00-3.66 4.44zM20 12c0-.78-.12-1.53-.33-2.24-.7.15-1.42.24-2.17.24a10 10 0 01-7.76-3.69A10.016 10.016 0 014 11.86c.01.04 0 .09 0 .14 0 4.41 3.59 8 8 8s8-3.59 8-8z"/></svg>
            </span>
            <span v-else>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                color="white"
                class="eyeqlp51 st-emotion-cache-fblp2m ex0cdmw0"
              >
                <!-- eslint-disable-next-line max-len -->
                <path d="M20 9V7c0-1.1-.9-2-2-2h-3c0-1.66-1.34-3-3-3S9 3.34 9 5H6c-1.1 0-2 .9-2 2v2c-1.66 0-3 1.34-3 3s1.34 3 3 3v4c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4c1.66 0 3-1.34 3-3s-1.34-3-3-3zm-2 10H6V7h12v12zm-9-6c-.83 0-1.5-.67-1.5-1.5S8.17 10 9 10s1.5.67 1.5 1.5S9.83 13 9 13zm7.5-1.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zM8 15h8v2H8v-2z"/></svg>
            </span>
          </div>
          <div class="message">
            {{ message.text }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="input-box"
      style="position: relative;"
    >
      <textarea
        v-model="newMessage"
        class="input"
        placeholder="입력해주세요."
        @keydown.enter.shift.prevent="sendMessage"
        @keyup.enter="sendMessage"
      />
      <button
        class="button_send"
        @click="sendMessage()"
      >
        <span class="icon">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            color="#A3A8B9"
            class="eyeqlp51 st-emotion-cache-fblp2m ex0cdmw0"
          >
            <!-- eslint-disable-next-line max-len -->
            <path d="M3 5.51v3.71c0 .46.31.86.76.97L11 12l-7.24 1.81c-.45.11-.76.51-.76.97v3.71c0 .72.73 1.2 1.39.92l15.42-6.49c.82-.34.82-1.5 0-1.84L4.39 4.58C3.73 4.31 3 4.79 3 5.51z"/></svg>
        </span>
      </button>
      <div>
        <div
          v-if="!isListening"
          class="button_rec"
          @click="startSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-microphone"
          />
        </div>
        <div
          v-else
          class="button_rec"
          @click="stopSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-square"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  useRouter,
  useRoute,
} from 'vue-router';
import {
  ref,
  onMounted,
  computed,
} from 'vue';
import { useSpeechRecognitionStore } from '../stores/storeSpeechRecognition';
import { userStore } from '../stores/storeAuth';
import {
  deleteLocalStorage,
  deleteStorage,
} from '@utils/storageUtils';
import { pushPage } from '@utils/route.helper';
import { errorDataType } from '@interface/error';
import { postUserSignout } from '@apis/auth';

const userData = userStore();
const currentPage = ref('');
const isFold = ref(true);
const foldMenu = () => {
  isFold.value = !isFold.value;
};

const isFoldManager = ref(true);
const foldMenuManager = () => {
  isFoldManager.value = !isFoldManager.value;
};
const route = useRoute();

const currentSecondNav = ref('');

const getSecondNavStyle = (value: string) => {
  const data = route.name;
  return { select_second_nav: data === value };
};

const selectCurrentSecondNav = (value: string) => {
  currentSecondNav.value = value;
  pushPage(`${value}`);
};

interface Message {
  text: string,
  from: 'me' | 'other',
}
const messages = ref<Message[]>([]);
const newMessage = ref<string>('');
const chatScreenRef = ref<HTMLElement | null>(null);

const scrollToBottom = () => {
  if (chatScreenRef.value) {
    chatScreenRef.value.scrollTop = chatScreenRef.value.scrollHeight;
  }
};

const message = ref('');
const speakingStyle = ref('');
const persona = ref({
  world: '우주 외계 행성',
  era: '미래',
  place: '지구',
  happenings: '오염과 자연 파괴',
  name: '마징가',
  age: '30',
  tribe: '로봇',
  gender: '남자',
  work: '정착',
  goal: '평화로운 삶',
  value: '자연',
  motivation: '자연보호',
  personality: '평화롭고 자상하며 이야기를 잘 들어주는',
  mbti: 'INTJ',
  favorite: '자연의 동물과 식물',
  specialty: '산책, 그림 그리기',
  topic: '자연보호',
  speaking_habits: '"징가~"',
});

const botMessage = ref('');
const postRequestChat = async () => {
  const params = {
    user_message: newMessage.value,
    speaking_style: speakingStyle.value,
    persona: persona.value,
  };
  try {
    if (newMessage.value.trim() !== '') {
      messages.value.push({
        text: newMessage.value,
        from: 'me',
      });
    }
    newMessage.value = '';
    // const result = await postChat(userData.userId, params);
    // console.log(result.data.answer, '💛💛💛💛💛💛💛💛');
    // botMessage.value = result.data.answer;

    messages.value.push({
      text: botMessage.value,
      from: 'other',
    });

    setTimeout(() => {
      scrollToBottom();
    }, 100);

    setTimeout(() => {
      scrollToBottom();
    }, 600);
    // }
  } catch (error) {
    // AntNotification.error({ message: error.message });
  }
};

// const sendMessage = () => {
//   postRequestChat();
//   // if (newMessage.value.trim() !== '') {
//   //   messages.value.push({
//   //     text: newMessage.value,
//   //     from: 'me',
//   //   });
//   //   newMessage.value = '';

//   //   setTimeout(() => {
//   //     messages.value.push({
//   //       text: botMessage.value,
//   //       from: 'other',
//   //     });
//   //   }, 500);

//   //   setTimeout(() => {
//   //     scrollToBottom();
//   //   }, 100);

//   //   setTimeout(() => {
//   //     scrollToBottom();
//   //   }, 600);
//   // }
// };
const sendMessage = () => {
  postRequestChat();
};

const speechRecognitionStore = useSpeechRecognitionStore();

const handleSpeechResult = (spokenText: string | null) => {
  if (spokenText) {
    newMessage.value = spokenText;
    sendMessage();
  }
};

const startSpeechListening = () => {
  speechRecognitionStore.startSpeechRecognition(handleSpeechResult);
};

const stopSpeechListening = () => {
  speechRecognitionStore.stopSpeechRecognition();
};

const isListening = computed(() => speechRecognitionStore.isListening);
// const newMessage = ref<string>('');
// const isListening = ref(false);
// // eslint-disable-next-line
// const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
// const recognition = new SpeechRecognition();
// recognition.lang = 'ko-KR'; // Set the language to Korean

// const startSpeechRecognition = () => {
//   recognition.value = new SpeechRecognition();
//   recognition.value.lang = 'ko-KR';

//   recognition.value.onstart = () => {
//     console.log('Speech recognition started');
//     isListening.value = true;
//   };

//   recognition.value.onend = () => {
//     console.log('Speech recognition ended');
//     isListening.value = false;
//     sendMessage();
//   };

//   recognition.value.onerror = (event: any) => {
//     console.error('Speech recognition error:', event.error);
//   };

//   recognition.value.onresult = (event: any) => {
//     const confidenceThreshold = 0.5; // Adjust as needed
//     const spokenText = event.results[0][0].transcript;
//     const { confidence } = event.results[0][0];
//     const { transcript } = event.results[0][0];
//     newMessage.value = transcript;
//     console.log('Spoken Text:', spokenText);
//     console.log('Confidence:', confidence);

//     if (confidence >= confidenceThreshold) {
//       console.log('Spoken Text:', spokenText);
//       console.log('Confidence:', confidence);
//       newMessage.value = transcript;
//     } else {
//       console.log('Speech confidence below threshold. Ignoring result.');
//     }

//     // Your logic to handle the spoken text...
//     // For example, you can update a ref or send a message
//   };

//   recognition.value.start();
// };

// const stopSpeechRecognition = () => {
//   recognition.value?.stop();
//   isListening.value = false;
// };

</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.wrap_layout {
  display: grid;
  grid-template-columns: 1fr 200fr;
  height: 100vh;
  overflow: hidden;
  font-family: 'Spoqa', sans-serif;
}

.wrap_header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 70px;
  font-family: 'Spoqa', sans-serif;
  font-size: 16px;
  color: #2B3668;
  background: white;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);

  span {
    cursor: pointer;
  }
}

.logo {
  padding: 30px 20px;
  margin-bottom: 21px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.button_login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: white;
  background: #2B3668;
  border-radius: 10px;
}

.wrap_content {
  position: relative;
  display: grid;
  width: 50vw;
  padding: 102px 70px 0 30px;
  margin: 0 auto;
}

.left_menu {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1003;
  display: flex;
  flex-direction: column;
  width: 250px;
  color: white;
  background: #2a3042;
}

.menu_category {
  padding: 0 20px;
  padding-bottom: 13px;
  font-size: 12px;
  font-weight: 900;
  color: #6a7187;
}

.wrap_list {
  height: 420px;
  padding: 0 0 0 5px;
  font-weight: 500;
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px;
  font-size: 14px;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;

  img {
    width: 30px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    transition: transform 0.3s ease-out;
  }
}

.list.active img {
  transform: rotate(180deg);
}

.list:active {
  background-color: #242939;
  background-size: 100%;
  opacity: 1;
  transition: background 0s;
}

.sublist {
  max-height: 0;
  padding-left: 28px;
  font-size: 14px;
  transition: max-height 2s ease-out;

  &.active {
    animation: showSublist 2s ease forwards;
  }

  div {
    padding: 10px 0 10px 10px;
    cursor: pointer;
  }

  div:hover {
    padding: 10px 0 10px 10px;
    color: white;
    cursor: pointer;
    transition: 0.5s;
  }
}

.menu_icon {
  margin-right: 13px;
}

@keyframes showSublist {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px; /* Adjust the value based on your content height */
  }
}

.select_second_nav {
  color: white !important;
}

.first_nav {
  color: #a6b0cf;
}

.separator {
  width: 180px;
  height: 1px;
  margin: 10px 90px 10px 40px;
  background-color: #79829c;
}

.chat_screen {
  flex-direction: column;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.chat_screen::-webkit-scrollbar {
  display: none;
}

.other {
  width: 100%;
  // width: fit-content;
  // max-width: 400px;
  height: auto;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  word-break: break-all;
  border-radius: 10px;
}

.me {
  width: 100%;
  // width: fit-content;
  // max-width: 400px;
  height: auto;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: #F7F8FB;
  border-radius: 10px;
}

.input-box {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 10px;
  border-radius: 0.5rem;
  -webkit-box-flex: 1;
  -webkit-box-align: center;
}

input {
  flex: 1;
  padding: 7px;
}

// button {
//   padding: 10px 10px;
//   color: white;
//   cursor: pointer;
//   background-color: #4caf50;
//   border: none;
//   border-radius: 5px;
// }

.button_send {
  position: absolute;
  right: 50px;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.input {
  width: 100%;
  height: 40px;
  max-height: 245px;
  padding: 10px;
  margin-right: 10px;
  font-size: 18px;
  resize: none;
  background-color: rgb(240, 242, 246);
  border-color: rgb(240, 242, 246);
  border-radius: 0.5rem;
}

.my_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgb(255, 108, 108);
  border-radius: 0.5rem;
}

.other_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgb(255, 189, 69);
  border-radius: 0.5rem;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap_icon_message {
  display: flex;
  gap: 10px;
}

.button_rec {
  bottom: 14.2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #EFF2F6;
  border-radius: 100%;
}
</style>
