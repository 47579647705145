<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="tabbar">
      <div class="back">
        <font-awesome-icon
          class="chevron"
          icon="fa-solid fa-chevron-left"
        />
        <div>Back</div>
      </div>
      <div class="other_profile">
        페르소나 설정(복사)
      </div>
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
      />
    </div>
    <div class="scroll">
      <div class="wrap_chat_screen">
        <div class="room_info">
          <div>설정을 시작합니다</div>
        </div>
        <pre>
      <code>
      [
          0: {
              "role": "user",
              "content": "start"
          }
      ]
      </code>
      </pre>
      </div>
    </div>
    <div class="wrap_input_screen">
      <div class="wrap_input">
        <textarea
          v-model="message"
          class="input"
          placeholder="Message"
          @keydown.enter.exact.prevent="handleShiftEnterKey"
        />
        <div
          v-if="isListening"
          class="button_rec"
        >
          <font-awesome-icon
            icon="fa-solid fa-microphone"
          />
        </div>
        <div
          v-if="!isListening"
          class="button_rec"
        >
          <font-awesome-icon
            icon="fa-solid fa-square"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { pushPage } from '@utils/route.helper';
import { errorType } from '@interface/error';

const isListening = ref(false);
const message = ref('');
const sendMessage = () => {
  console.log('sending messages', message);
};

const handleEnterKey = () => {
  sendMessage();
  message.value = '';
};

const handleShiftEnterKey = (event: any) => {
  if (event.shiftKey) {
    // 쉬프트 키를 누르면서 엔터 키를 눌렀을 때
    message.value += '\n'; // 또는 다른 줄바꿈 처리 방법을 사용할 수 있습니다.
  } else {
    // 쉬프트 키 없이 엔터 키만 눌렀을 때
    handleEnterKey();
  }
};
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 5px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
}

.room_info {
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.295);
  border-radius: 20px;
}

.bubble {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: #F7F8FB;
  border-radius: 10px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.input_chat {
  background: white;
}

.scroll {
  height: 90vh;
  overflow-y: scroll;
}

.input {
  width: 700px;
  height: 50px;
  max-height: 245px;
  padding: 15px;
  margin-right: 10px;
  font-size: 18px;
  resize: none;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.5rem;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.json-block {
  white-space: pre-wrap;
}

.toggle-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.back {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #FF5198;
  cursor: pointer;
}
</style>
