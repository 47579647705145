import {
  ref,
  reactive,
} from 'vue';
import { defineStore } from 'pinia';
import {
  iAvatar,
  iAvatarMessage,
  iGPTMessage,
} from '@interface/chat';

export const useAvatarStore = defineStore('avatar', () => {
  const currentAvatarId = ref(0);
  const settingSelectedAvatarId = ref();
  const currentAvatarInfo = reactive<iAvatar>({
    id: 0,
    world: '',
    era: '',
    place: '',
    happenings: '',
    name: '',
    age: '',
    tribe: '',
    gender: '',
    work: '',
    goal: '',
    value: '',
    motivation: '',
    personality: '',
    mbti: '',
    favorite: '',
    specialty: '',
    topic: '',
    speaking_habits: '',
    profile_url: '',
  });
  const avatarList = ref<iAvatar[]>([]);
  const avatarMessageList = ref<iAvatarMessage>({});
  const avatarLastChat = ref('');
  const currentUserId = ref('');

  const setCurrentAvatarInfo = (avatarInfo: iAvatar) => {
    currentAvatarId.value = avatarInfo.id;
    currentAvatarInfo.id = avatarInfo.id;
    currentAvatarInfo.world = avatarInfo.world;
    currentAvatarInfo.era = avatarInfo.era;
    currentAvatarInfo.place = avatarInfo.place;
    currentAvatarInfo.happenings = avatarInfo.happenings;
    currentAvatarInfo.name = avatarInfo.name;
    currentAvatarInfo.age = avatarInfo.age;
    currentAvatarInfo.tribe = avatarInfo.tribe;
    currentAvatarInfo.gender = avatarInfo.gender;
    currentAvatarInfo.work = avatarInfo.work;
    currentAvatarInfo.goal = avatarInfo.goal;
    currentAvatarInfo.value = avatarInfo.value;
    currentAvatarInfo.motivation = avatarInfo.motivation;
    currentAvatarInfo.personality = avatarInfo.personality;
    currentAvatarInfo.mbti = avatarInfo.mbti;
    currentAvatarInfo.favorite = avatarInfo.favorite;
    currentAvatarInfo.specialty = avatarInfo.specialty;
    currentAvatarInfo.topic = avatarInfo.topic;
    currentAvatarInfo.speaking_habits = avatarInfo.speaking_habits;
    currentAvatarInfo.profile_url = avatarInfo.profile_url;
  };

  const chooseAvatar = async (avatarInfo: iAvatar, userId: string) => {
    currentUserId.value = userId;
    setCurrentAvatarInfo(avatarInfo);
    console.log('avatar chosen:', currentAvatarInfo.name);
  };

  const addAvatarMessage = (avatarId: number, msg: iGPTMessage) => {
    if (!avatarMessageList.value[avatarId]) {
      avatarMessageList.value[avatarId] = [];
    }
    if (msg.role === 'assistant' && avatarId === currentAvatarId.value) { // 현재 채팅룸& 상대방의 chat인 경우
      avatarLastChat.value = msg.content;
    }
    avatarMessageList.value[avatarId].push(msg);
  };

  const getPersonaFromCurrentAvatarInfo = () => ({
    world: currentAvatarInfo.world,
    era: currentAvatarInfo.era,
    place: currentAvatarInfo.place,
    happenings: currentAvatarInfo.happenings,
    name: currentAvatarInfo.name,
    age: currentAvatarInfo.age,
    tribe: currentAvatarInfo.tribe,
    gender: currentAvatarInfo.gender,
    work: currentAvatarInfo.work,
    goal: currentAvatarInfo.goal,
    value: currentAvatarInfo.value,
    motivation: currentAvatarInfo.motivation,
    personality: currentAvatarInfo.personality,
    mbti: currentAvatarInfo.mbti,
    favorite: currentAvatarInfo.favorite,
    specialty: currentAvatarInfo.specialty,
    topic: currentAvatarInfo.topic,
    speaking_habits: currentAvatarInfo.speaking_habits,
  });

  // 페르소나 설정
  const setSettingAvatarInfo = (settingAvatarId: any) => {
    settingSelectedAvatarId.value = settingAvatarId;
  };

  const chooseSettingAvatar = async (settingAvatarId: any) => {
    settingSelectedAvatarId.value = settingAvatarId;
    setSettingAvatarInfo(settingAvatarId);
    console.log('avatar chosen:', settingSelectedAvatarId.value);
  };

  return {
    settingSelectedAvatarId,
    chooseSettingAvatar,
    currentAvatarId,
    currentAvatarInfo,
    avatarList,
    avatarMessageList,
    currentUserId,
    avatarLastChat,
    chooseAvatar,
    addAvatarMessage,
    getPersonaFromCurrentAvatarInfo,
  };
});

export default { useAvatarStore };
