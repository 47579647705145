<template>
  <div
    v-if="showModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeModal"
          />
        </div>
      </div>
      <div class="modal-content">
        삭제하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="button_okay"
          @click="deleteInfo"
        >
          삭제
        </div>
        <div
          class="modal_cancel"
          @click="closeModal"
        >
          취소
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showEditModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeEditModal"
          />
        </div>
      </div>
      <div class="modal-content">
        수정하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="button_okay"
          @click="editInfo"
        >
          수정
        </div>
        <div
          class="modal_cancel"
          @click="closeEditModal"
        >
          취소
        </div>
      </div>
    </div>
  </div>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div class="other_profile">
        아바타 페르소나 수정
      </div>
    </div>
    <div class="scroll">
      <div class="wrap_chat_screen">
        <div>
          <div class="persona-form">
            <div class="form-group">
              <label>선택하세요</label>
              <a-select
                ref="select"
                v-model:value="persona"
                class="dropdown"
                :options="personaList"
                @change="personaHandleChange"
              />
            </div>
            <div class="form-group">
              <label for="world">살고 있는 세계</label>
              <input
                v-model="selectedAvatarDetail.world"
                placeholder="Enter World"
              >
            </div>

            <div class="form-group">
              <label for="era">시대</label>
              <input
                v-model="selectedAvatarDetail.era"
                placeholder="Enter Era"
              >
            </div>

            <div class="form-group">
              <label for="place">장소</label>
              <input
                v-model="selectedAvatarDetail.place"
                placeholder="Enter Place"
              >
            </div>

            <div class="form-group">
              <label for="happenings">해프닝</label>
              <input
                v-model="selectedAvatarDetail.happenings"
                placeholder="Enter Happenings"
              >
            </div>

            <div class="form-group">
              <label for="name">이름</label>
              <input
                v-model="selectedAvatarDetail.name"
                placeholder="Enter Name"
              >
            </div>

            <div class="form-group">
              <label for="age">나이</label>
              <input
                v-model="selectedAvatarDetail.age"
                placeholder="Enter Age"
              >
            </div>

            <div class="form-group">
              <label for="tribe">종족</label>
              <input
                v-model="selectedAvatarDetail.tribe"
                placeholder="Enter Tribe"
              >
            </div>

            <div class="form-group">
              <label for="gender">성별</label>
              <input
                v-model="selectedAvatarDetail.gender"
                placeholder="Enter Gender"
              >
            </div>

            <div class="form-group">
              <label for="work">하는 일</label>
              <input
                v-model="selectedAvatarDetail.work"
                placeholder="Enter Work"
              >
            </div>

            <div class="form-group">
              <label for="goal">인생목표</label>
              <input
                v-model="selectedAvatarDetail.goal"
                placeholder="Enter Goal"
              >
            </div>

            <div class="form-group">
              <label for="value">가치관</label>
              <input
                v-model="selectedAvatarDetail.value"
                placeholder="Enter Value"
              >
            </div>

            <div class="form-group">
              <label for="motivation">동기부여</label>
              <input
                v-model="selectedAvatarDetail.motivation"
                placeholder="Enter Motivation"
              >
            </div>

            <div class="form-group">
              <label for="personality">성격</label>
              <input
                v-model="selectedAvatarDetail.personality"
                placeholder="Enter Personality"
              >
            </div>

            <div class="form-group">
              <label for="mbti">MBTI</label>
              <input
                v-model="selectedAvatarDetail.mbti"
                placeholder="Enter MBTI"
              >
            </div>

            <div class="form-group">
              <label for="favorite">좋아하는 것</label>
              <input
                v-model="selectedAvatarDetail.favorite"
                placeholder="Enter Favorite"
              >
            </div>

            <div class="form-group">
              <label for="specialty">특기</label>
              <input
                v-model="selectedAvatarDetail.specialty"
                placeholder="Enter Specialty"
              >
            </div>

            <div class="form-group">
              <label for="topic">주제</label>
              <input
                v-model="selectedAvatarDetail.topic"
                placeholder="Enter Topic"
              >
            </div>

            <div class="form-group">
              <label for="speaking_habits">말하는 습관</label>
              <input
                v-model="selectedAvatarDetail.speaking_habits"
                placeholder="Enter Speaking Habits"
              >
            </div>
            <div class="wrap_button">
              <div
                class="modal_cancel"
                @click="openModal"
              >
                삭제하기
              </div>
              <div
                class="button_okay"
                @click="openEditModal"
              >
                수정하기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import type { SelectProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { pushPage } from '@utils/route.helper';
import router from '@router';
import { errorType } from '@interface/error';
import {
  postAvatar,
  requestGetAvatarList,
  getAvatarDetail,
  deleteAvatar,
  putAvatarInfo,
} from '@apis/voicechat';

const personaMessages = ref([]);
const messageInput = ref('');
const isListening = ref(false);

const showModal = ref(false);
const showEditModal = ref(false);
const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};
const closeModal = () => {
  showModal.value = false;
};

const openModal = () => {
  showModal.value = true;
};

const closeEditModal = () => {
  showEditModal.value = false;
};

const openEditModal = () => {
  showEditModal.value = true;
};

const persona = ref('');
const personaList = ref<SelectProps['options']>([{
  value: '',
  label: '--- 선택 ---',
}]);

// avatar 불러와서 셀렉트에 추가
const getAvatar = async () => {
  try {
    const result = await requestGetAvatarList(); // Corrected function name
    result.data.avatars.forEach((res: any) => {
      const temp = {
        value: res.id,
        label: res.name,
      };
      personaList.value.push(temp);
    });
  } catch (error) {
    console.log(error);
  }
};

const selectedAvatarDetail = ref(
  {
    world: '',
    era: '',
    place: '',
    happenings: '',
    name: '',
    age: '',
    tribe: '',
    gender: '',
    work: '',
    goal: '',
    value: '',
    motivation: '',
    personality: '',
    mbti: '',
    favorite: '',
    specialty: '',
    topic: '',
    speaking_habits: '',
  },
);
const getSelectedAvatar = async (avatarId: number) => {
  const result = await getAvatarDetail(avatarId);
  selectedAvatarDetail.value = result.data;
};

const personaHandleChange = async () => {
  console.log(persona.value);
  const result = getSelectedAvatar(persona.value);
};

const editInfo = async () => {
  try {
    const result = await putAvatarInfo(persona.value, selectedAvatarDetail.value);
    console.log(result);
    showEditModal.value = false;
    AntNotification.success({ message: '아바타 페르소나를 수정했습니다.' });
    await getAvatar();
  } catch (error) {
    // AntNotification.error({ message: error?.message });
  }
};

const deleteInfo = async () => {
  await deleteAvatar(persona.value);
  showModal.value = false;
  // await getAvatar();
  router.go(0);
  // });
};

onMounted(async () => {
  await getAvatar();
});
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 5px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
}

.room_info {
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.295);
  border-radius: 20px;
}

.bubble {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: #F7F8FB;
  border-radius: 10px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.input_chat {
  background: white;
}

.scroll {
  height: 95vh;
  overflow-y: scroll;
}

.input {
  width: 700px;
  height: 50px;
  max-height: 245px;
  padding: 15px;
  margin-right: 10px;
  font-size: 18px;
  resize: none;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.5rem;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.json-block {
  white-space: pre-wrap;
}

.toggle-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.back {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #FF5198;
  cursor: pointer;
}

.codeblock {
  width: 100%;
  min-width: 700px;
}

.persona-form {
  width: 510px;
  padding: 20px;
  margin: 50px auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border: 1px solid #DADCE0;
  border-radius: 10px;
}

label {
  display: block;
  width: 200px;
  margin-bottom: 5px;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.edit_button {
  padding: 10px 15px;
  color: white;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
}

.delete_button {
  padding: 10px 15px;
  color: white;
  cursor: pointer;
  background-color: #c50e0e;
  border: none;
  border-radius: 5px;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.dropdown {
  width: 480px;
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  width: 300px;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-content {
  padding: 20px 0;
  color: #747474;
}
</style>
