import { ref } from 'vue';
import { defineStore } from 'pinia';
import { errorPageList } from '../common/route';
import { getRouteName } from '@utils/route.helper';

// eslint-disable-next-line import/prefer-default-export
export const useCheckRouteStore = defineStore('checkRoute', () => {
  const isErrorPage = ref(false);

  const initRoute = (routeName: string) => {
    if (errorPageList.includes(getRouteName(routeName))) {
      isErrorPage.value = true;
      return;
    }
    isErrorPage.value = false;
  };

  return {
    isErrorPage,
    initRoute,
  };
});
