<template>
  <div
    v-if="isHasSpeakerModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeHasSpeakerModal"
          />
        </div>
      </div>
      <div class="modal-content">
        <div>현재 나의 음성을 등록하지 않아 채팅에 참여할 수 없습니다.</div>
        <div>음성 등록으로 이동하시겠습니까?</div>
      </div>
      <div class="wrap_button">
        <div
          class="modal_cancel"
          @click="closeHasSpeakerModal"
        >
          나중에 하기
        </div>
        <div
          class="button_okay"
          @click="goCustomVoice()"
        >
          이동하기
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showInviteModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeInviteModal"
          />
        </div>
      </div>
      <div class="modal-content">
        채팅방으로 초대하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="modal_cancel"
          @click="closeInviteModal"
        >
          취소
        </div>
        <div
          class="button_okay"
          @click="confirm()"
        >
          초대
        </div>
      </div>
    </div>
  </div>
  <div class="wrap_layout">
    <div class="side_menu">
      <!-- 상단 뒤로가기 -->
      <div class="title">
        커스텀보이스 채팅 BETA
      </div>
      <!-- 나의 프로필 -->
      <div
        class="my_profile"
        @click="pushPage('myInfo')"
      >
        <div>
          <img
            v-if="isAnimal(userDetail.profile_url, ['fox', 'shark', 'tiger', 'koala', 'bear', 'unicorn', 'lion', 'dog'])"
            class="my_picture"
            :src="require(`@assets/${userDetail.profile_url}`)"
            alt="Profile Picture"
          >
          <img
            v-else
            class="my_picture"
            src="@assets/emoji_nobody.png"
          >
        </div>
        <div class="profile_info">
          <div>{{ userDetail.nick_name }}</div>
          <div class="additional_info">
            ({{ userDetail.user_id }})
          </div>
        </div>
      </div>
      <!-- 1.user list -->
      <div v-if="tabData.tabName === 'users'">
        <!-- 검색 -->
        <div class="wrap_search">
          <div class="search-bar">
            <input
              type="text"
              class="search-input"
              placeholder="Search"
              @input="handleSearchUserInput"
            >
          <!-- <button class="search-button">
            Search
          </button> -->
          </div>
        </div>
        <div
          class="wrap_friend_list"
        >
          <div class="talk_title">
            다른 사용자와 대화해보세요!
          </div>
        </div>
        <div
          v-if="filteredUserRoomList.length == 0"
          class="no_user"
        >
          접속한 사용자가 없습니다.
        </div>
        <div
          v-else
          class="scroll wrap_friend"
        >
          <div
            v-for="(room, index) in filteredUserRoomList"
            :key="index"
          >
            <div
              class="other_profile"
              @click="openInviteModal(room, room.isActive)"
            >
              <div>
                <img
                  v-if="isAnimal(room.profileUrl, ['fox', 'shark', 'tiger', 'koala', 'bear', 'unicorn', 'lion', 'dog'])"
                  class="other_picture"
                  :src="require(`@assets/${room.profileUrl}`)"
                  alt="Profile Picture"
                >
                <img
                  v-else
                  class="other_picture"
                  src="@assets/emoji_nobody.png"
                >
              </div>
              <div class="other_profile_info">
                <div>{{ room.nickName !== '' ? room.nickName : '닉네임없음' }}</div>
                <!-- <div class="other_additional_info">
                    RoomId [{{ room.roomId }}]
                  </div> -->
              </div>
              <div>
                <div :class="{ 'dot': true, 'active-dot': room.isActive, 'inactive-dot': !room.isActive }"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2.avatar list -->
      <div
        v-if="tabData.tabName === 'avatar'"
      >
        <div class="wrap_search">
          <div class="search-bar">
            <input
              type="text"
              class="search-input"
              placeholder="Search"
              @input="handleInput"
            >
          <!-- <button class="search-button">
            Search
          </button> -->
          </div>
        </div>
        <div class="wrap_friend_list">
          <div class="talk_title">
            Chat GPT 아바타와 대화해보세요!
          </div>
        </div>
        <div class="scroll wrap_friend">
          <div
            v-for="(avatar, index) in filteredAvatarList"
            :key="index"
          >
            <div
              class="other_profile"
              @click="chooseAvatar(avatar)"
            >
              <div>
                <img
                  class="other_picture"
                  src="@assets/emoji_robot.png"
                >
              </div>
              <div class="other_profile_info">
                <div>{{ avatar.name }}</div>
                <div class="other_additional_info">
                  {{ avatar.topic }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 3.설정 -->
      <div v-if="tabData.tabName === 'settings'">
        <ul>
          <li
            v-if="userDetail.user_id === 'develop'"
            @click="selectCurrentMenu('personaChat')"
          >
            <font-awesome-icon
              class="icon_menu sky"
              icon="fa-solid fa-pen-to-square"
            />아바타 페르소나 생성
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            v-if="userDetail.user_id === 'develop'"
            @click="selectCurrentMenu('personaEdit')"
          >
            <font-awesome-icon
              class="icon_menu red"
              icon="fa-solid fa-pen"
            />아바타 페르소나 수정
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            v-if="userDetail.user_id == 'develop'"
            @click="selectCurrentMenu('customVoiceLearning')"
          >
            <font-awesome-icon
              class="icon_menu orange"
              icon="fa-solid fa-microphone"
            />아바타 커스텀보이스
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            v-else
            @click="selectCurrentMenu('customVoiceRead')"
          >
            <font-awesome-icon
              class="icon_menu orange"
              icon="fa-solid fa-microphone"
            />나의 커스텀보이스
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            @click="selectCurrentMenu('myInfo')"
          >
            <font-awesome-icon
              class="icon_menu grey"
              icon="fa-solid fa-user"
            />내정보 수정
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            v-if="userDetail.user_id != 'develop'"
            @click="selectCurrentMenu('askAdmin')"
          >
            <font-awesome-icon
              class="icon_menu purple"
              icon="fa-solid fa-circle-question"
            />관리자에게 문의하기
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li
            v-if="userDetail.user_id === 'develop'"
            @click="selectCurrentMenu('askBoard')"
          >
            <font-awesome-icon
              class="icon_menu purple"
              icon="fa-solid fa-circle-question"
            />
            문의수신
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
          <li @click="goSignOut()">
            <font-awesome-icon
              class="icon_menu blue"
              icon="fa-solid fa-right-from-bracket"
            />로그아웃
            <div class="chevron-container">
              <font-awesome-icon
                class="chevron_right"
                icon="fa-solid fa-chevron-right"
              />
            </div>
          </li>
        </ul>
      </div>
      <div v-if="isAvatarTutorialInfo">
        <div class="balloon">
          아바타와도 대화할 수 있어요!
        </div>
      </div>
      <!-- 하단 탭바 -->
      <div class="tabbar">
        <div
          :class="{ 'active-tab': tabData.tabName === 'users' }"
          @click="changeTab('users')"
        >
          <img
            class="tabbar_icon"
            src="@assets/people_black_24dp.svg"
          >
        </div>
        <div
          :class="{ 'active-tab': tabData.tabName === 'avatar' }"
          @click="changeTab('avatar')"
        >
          <img
            class="tabbar_icon"
            src="@assets/icon_robot.svg"
          >
        </div>
        <div
          :class="{ 'active-tab': tabData.tabName === 'settings' }"
          @click="changeTab('settings')"
        >
          <img
            class="tabbar_icon"
            src="@assets/icon_settings.svg"
          >
        </div>
      </div>
    </div>
    <div class="main_screen">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import type { SelectProps } from 'ant-design-vue';
import {
  iAvatar,
  iUserRoom,
} from '@interface/chat';
import { useWebSocketStore } from '../stores/storeWebSocket';
import { useAvatarStore } from '../stores/storeAvatar';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import {
  deleteLocalStorage,
  deleteStorage,
} from '@utils/storageUtils';
import { pushPage } from '@utils/route.helper';
import { routeHelper } from '@utils';
import router from '@router';
import { errorType } from '@interface/error';
import {
  getUserList,
  getSignedUserInfo,
  requestGetAvatarList,
  getAvatarDetail,
} from '@apis/voicechat';

const { pushQueryPage } = routeHelper;
const userData = userStore();
const client = useWebSocketStore();
const avatarStore = useAvatarStore();
const tutorialData = tutorialStore();
const tabData = tabStore();
const searchUser = ref('');
const searchAvatar = ref('');
const roomInformation = ref();
const showInviteModal = ref(false);
const closeInviteModal = () => {
  showInviteModal.value = false;
};
const isAvatarTutorialInfo = ref(false);

const isTutorial = () => {
  if (tutorialData.isAvatarTutorial) {
    isAvatarTutorialInfo.value = true;
  } else {
    isAvatarTutorialInfo.value = false;
  }
};

// eslint-disable-next-line camelcase, arrow-body-style
const isAnimal = (profileUrl: string, animals: string[]) => {
  return profileUrl && animals.some((animal) => profileUrl.includes(animal));
};

const currentSettingAvatar = computed(() => avatarStore.settingSelectedAvatarId);

const joinChatRoom = (roomInfo: iUserRoom) => {
  console.log(roomInfo);
  closeInviteModal();
  if (roomInfo.roomId && roomInfo.isActive) {
    // 채팅룸으로 이동 (조인 되어 있는 상태)
    console.log('joinChatRoom: 채팅룸으로 이동');
    client.joinChatRoom(roomInfo.roomId);

    console.log('roomId:', roomInfo.roomId);
    router.push({
      name: 'chatUser',
      params: { id: roomInfo.roomId },
    });
  }
  if (roomInfo.roomId && !roomInfo.isActive) {
    // 채팅룸 조인
    console.log('joinChatRoom: 채팅룸 조인 토큰');
    client.sendToken({
      event: 'JOIN_ROOM',
      roomId: roomInfo.roomId,
      data: { user_id: client.sender },
    });
  }
  if (!roomInfo.roomId && !roomInfo.isActive) {
    console.log('1️⃣joinChatRoom: 채팅룸 만들기 토큰', roomInfo.userId, client.sender);
    client.sendToken({
      event: 'CREATE_ROOM',
      roomId: '',
      data: {
        user_list: [
          client.sender,
          roomInfo.userId,
        ],
      },
    });
  }
};
const changeTab = (tab: string) => {
  tabData.tabName = tab;
  if (tabData.tabName !== 'avatar') {
    searchAvatar.value = '';
    tutorialData.isAvatarTutorial = false;
    isTutorial();
  }
};
const currentMenu = ref('');
const selectCurrentMenu = (value: string) => {
  currentMenu.value = value;
  pushPage(`${value}`);
};

const confirm = () => {
  joinChatRoom(roomInformation.value);
};

const openInviteModal = (roomInfo: iUserRoom, isActive: boolean) => {
  if (isActive) {
    console.log('go to room');
    joinChatRoom(roomInfo);
  } else {
    showInviteModal.value = true;
    roomInformation.value = roomInfo;
  }
};

const userList = ref<any[]>([]);
const userRoomList = computed(() => client.activeUserRooms);
const avatarList = ref<iAvatar[]>([]);

const getUser = async () => {
  const result = await getUserList();
  userList.value = result.data.users;
};

const userDetail = ref<any>({});
const getUserDetail = async () => {
  const result = await getSignedUserInfo();
  userDetail.value = result.data;
  console.log(userDetail.value);
};

const persona = ref('');
const personaList = ref<SelectProps['options']>([{
  value: '',
  label: '--- 선택 ---',
}]);

const selectedAvatarDetail = ref<any[]>([]);
const getSelectedAvatar = async (avatarId: number) => {
  const result = await getAvatarDetail(avatarId);
  selectedAvatarDetail.value = result.data;
  console.log('selected avatar detail', selectedAvatarDetail.value);
};

// const personaHandleChange = async () => {
//   console.log(persona.value);
//   const result = getSelectedAvatar(persona.value);
//   avatarStore.chooseSettingAvatar(persona.value);
// };

const getAvatarList = async () => {
  const result = await requestGetAvatarList();
  avatarList.value = result.data.avatars;
};

// avatar 불러와서 셀렉트에 추가
const getAvatar = async () => {
  try {
    const result = await requestGetAvatarList(); // Corrected function name
    result.data.avatars.forEach((res: any) => {
      const temp = {
        value: res.id,
        label: res.name,
      };
      personaList.value.push(temp);
    });
  } catch (error) {
    console.log(error);
  }
};

const chooseAvatar = (avatarInfo: iAvatar) => {
  avatarStore.chooseAvatar(avatarInfo, userDetail.value.user_id);
  pushQueryPage('chatAvatar', avatarInfo.id.toString());
};

// const requestSendToken = async () => {
//   const data = {
//     sender: userDetail.value.user_id,
//     event: 'ACTIVE_USERS',
//     roomId: 0,
//     data: {},
//   };
//   try {
//     await client.sendToken(data);
//   } catch (e) {
//     console.log(e);
//   }
// };
const handleInput = (event: any) => {
  searchAvatar.value = event.target.value;
};

const handleSearchUserInput = (event: any) => {
  searchUser.value = event.target.value;
};

const filteredUserRoomList = computed(() => {
  const keyword = searchUser.value.toLowerCase();
  return userRoomList.value.filter((room) => room.nickName.toLowerCase().includes(keyword));
});

const filteredAvatarList = computed(() => {
  const keyword = searchAvatar.value.toLowerCase();
  return avatarList.value.filter((avatar) => avatar.name.toLowerCase().includes(keyword));
});
const connectWs = async () => {
  try {
    client.connectWebSocket(userDetail.value.user_id);
  } catch (e) {
    console.log(e);
  }
};

// const goSignOut = () => {
//   pushPage('login');
//   tabData.tabName = 'users';
// };

const goSignOut = () => {
  try {
    tabData.tabName = 'users';
    deleteStorage('accessToken');
    deleteStorage('currentRoomInfo');
    deleteStorage('userId');
    deleteStorage('client.currentRoomInfo');
    deleteLocalStorage('accessToken');
    deleteLocalStorage('currentRoomInfo');
    deleteLocalStorage('userId');
    deleteLocalStorage('client.currentRoomInfo');
    userData.$reset();
    client.resetWebSocketStore();
    pushPage('login');
    console.log(client.currentRoomInfo, 'room info<--------------');
  } catch (error) {
    console.log(error);
  }
};
const isHasSpeakerModal = ref(false);
const closeHasSpeakerModal = () => {
  isHasSpeakerModal.value = false;
};
const openHasSpeakerModal = () => {
  isHasSpeakerModal.value = true;
};

const goCustomVoice = async () => {
  await closeHasSpeakerModal();
  pushPage('customVoiceRead');
};

onMounted(async () => {
  await isTutorial();
  await getUser();
  await getUserDetail();
  await getAvatarList();
  await connectWs();
  await getAvatar();
  if (!userDetail.value.hasSpeaker) {
    await openHasSpeakerModal();
  }
});
</script>

<style lang="scss" scoped>
.wrap_layout {
  display: flex;
  height: 100vh;
}

.side_menu {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  border-right: 1px solid #EAEAEA;
}

.main_screen {
  display: flex;
  flex: 1;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-bottom: 1px solid #EAEAEA;
}

.my_profile{
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  padding: 20px 15px;
  cursor: pointer;
}

.talk_title {
  margin-bottom: 10px;
}

.other_profile {
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  padding: 8px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.other_profile:hover {
  background: rgb(235, 235, 235);
  border-radius: 10px;
}

.my_picture {
  width: 70px;
  border-radius: 50px;
}

.other_picture {
  width: 50px;
  border-radius: 100%;
}

.wrap_search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  display: flex;
  width: 95%;
  max-width: 350px;
  overflow: hidden;
  border-radius: 30px;
}

.search-input {
  flex: 1;
  padding: 8px 20px;
  background-color: #F4F4F4;
  outline: none;
}

.profile_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5px;
}

.additional_info {
  color:#8E8E8E;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 7px;
  margin-bottom: 5px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_friend_list {
  padding: 30px 20px 0 20px;
}

.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 350px;
  height: 60px;
  padding-top: 15px;
  text-align: center;
  border-top: 1px solid #EAEAEA;
  div {
    cursor: pointer;
  }
}

.tabbar_icon {
  height: 30px;
}

.active-tab {
  filter: invert(49%) sepia(28%) saturate(3743%) hue-rotate(307deg) brightness(101%) contrast(103%);
}

ul {
  padding: 0 20px;
}

ul >li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  cursor: pointer;
}

li:hover {
  background: rgb(235, 235, 235);
  border-radius: 10px;
}

ul > li > img {
  width: 15px;
  margin-right: 10px;
}

.chevron {
  padding: 0 10px 0 20px;
  color: #FF5198;
  cursor: pointer;
}

.dropdown {
  width: 200px;
  margin-left: 30px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: grey;
  border-radius: 100%;
}

.active {
  color: green; /* or your desired color for 'Active' */
}

.inactive {
  color: gray; /* or your desired color for 'Inactive' */
}

.active-dot {
  background-color: green; /* or your desired color for 'Active' */
}

.inactive-dot {
  background-color: gray; /* or your desired color for 'Inactive' */
}

.chevron_right {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #bfbfbf;
  cursor: pointer;
}

.icon_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 6px;
  margin-right: 10px;
  color: white;
  background: pink;
  border-radius: 5px;
}

.purple {
  background: #CB68E0;
}

.blue {
  background: #208BFA;
}

.orange {
  background: #FE8A1D;
}

.red {
  background: #FD254C;
}

.sky {
  background: #2CA0D5;
}

.grey {
  background: #838388;
}

.no_user {
  padding: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #8E8E8E;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"] {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #F4F4F4;
}

.wrap_friend {
  height: 72.5vh;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.modal {
  width: 450px;
}

.modal-content {
  div {
    margin-bottom: 10px;
  }
}

.balloon {
  position: absolute;
  bottom: 70px;
  left: 140px;
  padding: 10px 20px;
  color: white;
  background-color: #FF5198;
  border-radius: 20px;
}

.balloon::before {
  position: absolute;
  top: 100%;
  left: 30px; /* 뾰족한 부분의 위치 조정 */
  content: '';
  border-color: transparent transparent #FF5198 transparent;
  border-style: solid;
  border-width: 8px;
  transform: rotate(180deg);
}

</style>
