import {
  createRouter,
  createWebHashHistory, // 변경된 부분
  RouteRecordRaw,
} from 'vue-router';
import { useCheckRouteStore } from '../stores/route';
import {
  home,
  login,
  chatAvatar,
  landingPage,
  signUp,
  chatUser,
  customVoiceLearning,
  askAdmin,
  personaChat,
  defaultLayout,
  personaCopy,
  personaEdit,
  customVoiceRead,
  customVoiceChat,
  askBoard,
  myInfo,
} from './routePaths';
import {
  Login,
  MainDesktop,
  ChatAvatar,
  ChatUser,
  SignUp,
  LandingPage,
  CustomVoiceLearning,
  AskAdmin,
  MyInfo,
  PersonaChat,
  PersonaCopy,
  PersonaEdit,
  CustomVoiceRead,
  CustomVoiceChat,
  AskBoard,
} from '@views';
import {
  checkAdmin,
  checkToken,
} from '@utils/authentication';
import { routeHelper } from '@utils';
import { DefaultLayout } from '@layouts';

const routes: Array<RouteRecordRaw> = [
  {
    path: home,
    name: routeHelper.getRouteName(home),
    component: LandingPage,
  },
  {
    path: defaultLayout,
    name: routeHelper.getRouteName(defaultLayout),
    component: DefaultLayout,
    children: [
      {
        path: chatAvatar,
        name: routeHelper.getRouteName(chatAvatar),
        component: ChatAvatar,
      },
      {
        path: chatUser,
        name: routeHelper.getRouteName(chatUser),
        component: ChatUser,
      },
      {
        path: customVoiceLearning,
        name: routeHelper.getRouteName(customVoiceLearning),
        component: CustomVoiceLearning,
      },
      {
        path: customVoiceChat,
        name: routeHelper.getRouteName(customVoiceChat),
        component: CustomVoiceChat,
      },
      {
        path: askAdmin,
        name: routeHelper.getRouteName(askAdmin),
        component: AskAdmin,
      },
      {
        path: askBoard,
        name: routeHelper.getRouteName(askBoard),
        component: AskBoard,
      },
      {
        path: personaChat,
        name: routeHelper.getRouteName(personaChat),
        component: PersonaChat,
      },
      {
        path: personaCopy,
        name: routeHelper.getRouteName(personaCopy),
        component: PersonaCopy,
      },
      {
        path: personaEdit,
        name: routeHelper.getRouteName(personaEdit),
        component: PersonaEdit,
      },
      {
        path: customVoiceRead,
        name: routeHelper.getRouteName(customVoiceRead),
        component: CustomVoiceRead,
      },
      {
        path: myInfo,
        name: routeHelper.getRouteName(myInfo),
        component: MyInfo,
      },
    ],
  },
  {
    path: login,
    name: routeHelper.getRouteName(login),
    component: Login,
  },
  {
    path: signUp,
    name: routeHelper.getRouteName(signUp),
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHashHistory(), // 변경된 부분
  routes,
});

router.beforeEach(async (to, from, next) => {
  const checkRoute = useCheckRouteStore();

  checkRoute.initRoute(to.path);
  if (to.path === login) {
    await checkToken(next, 'login');
    return;
  }

  if (to.path === home) {
    await checkToken(next, 'home');
    return;
  }

  await checkToken(next, 'apply');
});

// 라우터 정보가 변경 될때마다 호출
// 매 페이지 인증검사 실시
// 참고페이지
// https://router.vuejs.org/kr/api/#router-beforeeach

// router.beforeEach(async (to, from, next) => {
//   if (to.name === 'login' || from.name === 'login') {
//     next();
//   } else {
//     // 쿠키를 체크 합니다.
//     const errorType = await authentication.cookieCheck();
//     // console.log('errorType', errorType);

//     if (errorType === 'success') {
//       next();
//     } else if (errorType === 'refreshTokenRefresh') {
//       // 쿠키가 만료되어서 로그인 페이지로 이동 합니다.
//       next({
//         name: routeHelper.getRouteName(login),
//         params: {
//           refreshTokenEnd: 'refreshTokenEnd',
//         },
//       });
//     } else if (errorType === 'accessTokenRefresh') {
//       // cookieCheck에 의해 accessToken이 재발급 되었으므로, 이동합니다.
//       next();
//     }
//   }
// });

export default router;
