<template>
  <div>
    <MainDesktop
      v-if="isDesktop"
      class="desktop"
    />
    <MainMobile
      v-else
      class="mobile"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
} from 'vue';
import {
  MainDesktop,
  MainMobile,
} from '@views';
import { pushPage } from '@utils/route.helper';
import { errorType } from '@interface/error';

const isDesktop = ref(window.innerWidth >= 769);

// 창 크기가 변할 때마다 체크
window.addEventListener('resize', () => {
  isDesktop.value = window.innerWidth >= 769;
});

</script>

<style lang="scss" scoped>
/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
