import { NavigationGuardNext } from 'vue-router';
import { ref } from 'vue';
import {
  CLIENT_ID,
  APP_RETURN_URL,
  APP_SCOPE,
} from '@common/appEnv';
import { userStore } from '../stores/storeAuth';
import {
  deleteStorage,
  setStorage,
  getStorage,
} from '@utils/storageUtils';
import { login } from '@router/routePaths';
import router from '@router';
import { errorDataType } from '@interface/error';
import {
  requestPostRefreshToken,
  validationCheckTimestamp,
} from '@apis/common';
import axiosInstance from '@apis/axiosInstance';
import { postUserSignout } from '@apis/auth';

export const issueRefreshToken = async () => {
  const refreshTokenValue = getStorage('refreshToken');
  const userData = userStore();

  const result = await requestPostRefreshToken(refreshTokenValue ?? '', userData.userId);

  const { accessToken } = result.data;
  const { refreshToken } = result.data;

  // eslint-disable-next-line camelcase
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  setStorage('accessToken', accessToken);
  setStorage('refreshToken', refreshToken);
};

export const checkToken = async (next: NavigationGuardNext, type: string) => {
  const accessToken = getStorage('accessToken');
  const refreshToken = getStorage('refreshToken');
  const isMain = type === 'terms';
  const isHome = type === 'home';
  try {
    if (!accessToken) {
      if (refreshToken) {
        await issueRefreshToken();
        // eslint-disable-next-line no-unused-expressions
        next();
      } else {
        // eslint-disable-next-line no-unused-expressions
        next();
      }
    } else {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      // eslint-disable-next-line no-unused-expressions
      isMain ? next() : next();
    }
  } catch (e) {
    const err = e as errorDataType;
    if (err.errorCode === 'ERR_OAUTH_TOKEN') {
      await postUserSignout(refreshToken ?? '');
      deleteStorage('accessToken');
      deleteStorage('refreshToken');
      // eslint-disable-next-line no-unused-expressions
      isMain ? next(login) : next();
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkAdmin = async (next: NavigationGuardNext) => {
  const userData = userStore();
  try {
    if (userData.userRole === '매니저' || userData.userRole === '어드민') {
      next();
    } else {
      alert('접속권한이 없습니다.');
      router.push({ name: 'home' });
    }
  } catch (e) {
    const err = e as errorDataType;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkAdditionInfo = async (next: NavigationGuardNext) => {
  const userData = userStore();
  try {
    if (userData.userRole === '매니저') {
      next();
    } else {
      next(login);
    }
  } catch (e) {
    const err = e as errorDataType;
  }
};

export default { checkToken };
