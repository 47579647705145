import { useAudioStore } from '../stores/storeAudio';
import { DEV_URL } from './endpoints';

export const postStreams = (mode: string, quest: string, type: string) => {
  const audioStore = useAudioStore();
  const guestJsonString = JSON.stringify({
    quest,
    speaker_base64_string: type,
  });
  const userJsonString = JSON.stringify({
    quest,
    user_id: type,
  });
  const avatarJsonString = JSON.stringify({
    quest,
    avatar_id: type,
  });
  let jsonString;
  if (mode === 'user') {
    jsonString = userJsonString;
  } else if (mode === 'avatar') {
    jsonString = avatarJsonString;
  } else {
    jsonString = guestJsonString;
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Adjust the content type as needed
      // Add any other headers required by your server
    },
    // Include any request body data if required
    body: jsonString,
  };

  console.log('params:', requestOptions);

  function onChunkedResponseComplete(result: any) {
    console.log('all done!', result);
  }

  function onChunkedResponseError(err: any) {
    console.error(err);
  }

  function processChunkedResponse(response: any) {
    let text = '';
    const reader = response.body.getReader();

    function appendChunks(result: any) {
      const decoder = new TextDecoder();
      const chunk = decoder.decode(result.value || new Uint8Array(), { stream: !result.done });
      text += chunk;
      // Check if the text contains a newline character
      const newlineIndex = text.indexOf('\n');
      if (newlineIndex !== -1) {
        // Extract the complete Base64 chunk
        const completeBase64Chunk = text.substring(0, newlineIndex);
        // Process the complete Base64 chunk
        audioStore.addAudioDataFromBase64Chunk(completeBase64Chunk);
        console.log('got chunk of', completeBase64Chunk.length, 'bytes');

        // Remove the processed chunk from text
        text = text.substring(newlineIndex + 1);
      }
      if (result.done) {
        console.log('returning');
        if (text.length > 0) {
          console.log('got last chunk of', text.length, 'bytes');
          audioStore.addAudioDataFromBase64Chunk(text);
        }
        return '';
      }
      console.log('recursing');
      return reader.read().then(appendChunks);
    }
    return reader.read().then(appendChunks);
  }

  fetch(`${DEV_URL}voice/streams/${mode}`, requestOptions)
    .then(processChunkedResponse)
    .then(onChunkedResponseComplete)
    .catch(onChunkedResponseError);
};

// Create a request object with the POST method
export const postStreamsGuest = (params: any) => {
  postStreams('guest', params.quest, params.speaker_base64_string);
};

export const postStreamsUser = (params: any) => {
  postStreams('user', params.quest, params.user_id);
};

export const postStreamsAvatar = (params: any) => {
  postStreams('avatar', params.quest, params.avatar_id);
};

export default { postStreams };
