<template>
  <div
    v-if="showInviteModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeInviteModal"
          />
        </div>
      </div>
      <div class="modal-content">
        채팅방에 참여하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="modal_cancel"
          @click="rejectInvite"
        >
          취소
        </div>
        <div
          class="button_okay"
          @click="confirmInvite"
        >
          수락
        </div>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
  onBeforeUnmount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useWebSocketStore } from './stores/storeWebSocket';
import {
  tabStore,
  userStore,
  tutorialStore,
} from './stores/storeAuth';
import { routeHelper } from '@utils';
import router from '@router';

const client = useWebSocketStore();
const { pushQueryPage } = routeHelper;
const showInviteModal = ref(false);
const roomInformation = ref();

const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

const closeInviteModal = () => {
  showInviteModal.value = false;
};

const goChatRoom = () => {
  if (client.invitingInfo.roomId) {
    client.joinChatRoom(client.invitingInfo.roomId);
    router.push({
      name: 'chatUser',
      params: { id: client.invitingInfo.roomId },
    });
  }
};

const confirmInvite = async () => {
  console.log('joinChatRoom: 채팅룸 조인 토큰');

  await client.sendToken({
    event: 'JOIN_ROOM',
    roomId: client.invitingInfo.roomId,
    data: { user_id: client.sender },
  });

  showInviteModal.value = false;
  tabData.sideMenu = false;

  // 3초 후에 goChatRoom 함수 실행
  setTimeout(() => {
    goChatRoom();
  }, 2000);
};

const rejectInvite = () => {
  console.log('isInvited Cancel');
  console.log('joinChatRoom: 채팅룸 조인 토큰');
  client.sendToken({
    event: 'REJECT_ROOM',
    roomId: client.invitingInfo.roomId,
    data: { user_id: client.sender },
  });
  showInviteModal.value = false;
};

watch(
  () => client.isInvited,
  (newValue, oldValue) => {
    console.log('watch :', newValue, oldValue);
    // 속성 값이 변경될 때 실행되는 로직
    if (newValue) {
      showInviteModal.value = true;
    }
  },
);

onBeforeUnmount(() => {
  client.disconnectWebSocket();
});
</script>

<style lang="scss">
@import '@styles/reset';
@import '@styles/dullabam';

// @font-face {
//   font-family: 'SCDream';
//   src: url('./assets/fonts/SCDream5.otf') format('truetype');
//   font-style: normal;
//   font-weight: 500;
// }

@font-face {
  font-family: 'Spoqa';
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff") format('truetype');
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

html,
body {
  height: 100%;
  font-family: 'SCDream', 'Spoqa', sans-serif;
  scrollbar-width: none;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}
</style>
