<template>
  <div class="center-container">
    <div class="wrap_signin">
      <div class="wrap_next">
        <img
          src="@assets/whale.png"
          class="signin_image"
        >
      </div>
      <div class="signin_title">
        커스텀보이스 채팅 BETA
      </div>
      <div class="signin_subtitle">
        계정과 비밀번호를 입력해주세요.
      </div>
      <div class="wrap_signin_input">
        <input
          v-model="userId"
          type="email"
          placeholder="아이디"
          class="signin_input"
        >
        <div class="wrap_string">
          <div style="width: 85%;  border: 1px #EAEAEA solid"/>
        </div>
        <input
          v-model="userPwd"
          type="password"
          placeholder="비밀번호"
          class="signin_input"
        >
      </div>
      <div class="wrap_next">
        <div
          class="next"
          @click="postSignIn()"
        >
          NEXT
        </div>
      </div>
      <div
        class="sign_up"
        @click="pushPage('signUp')"
      >
        계정이 없으신가요?
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import {
  ref,
  watch,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import { Modal } from 'ant-design-vue';
import { useWebSocketStore } from '../stores/storeWebSocket';
import { userStore } from '../stores/storeAuth';
import {
  setLocalStorage,
  setStorage,
  getStorage,
} from '@utils/storageUtils';
import { pushPage } from '@utils/route.helper';
import router from '@router';
import { errorType } from '@interface/error';
import {
  postLogin,
  getSignedUserInfo,
} from '@apis/voicechat';
import axiosInstance from '@apis/axiosInstance';

const userData = userStore();
const userId = ref('');
const userPwd = ref('');
const isLoggingIn = ref(false);
const keeplogin = ref(false);
const route = useRoute();

// 로그인
const postSignIn = async () => {
  if (isLoggingIn.value) {
    return; // 이미 요청 중이면 중복 요청 방지
  }
  if (!userId.value) {
    alert('이메일을 입력해주세요');
    return;
  }
  if (!userPwd.value) {
    alert('비밀번호를 입력해주세요');
    return;
  }

  const params = {
    user_id: userId.value,
    pwd: userPwd.value,
  };

  console.log('nextStep', userId.value);

  try {
    isLoggingIn.value = true;
    const result = await postLogin(params);
    userData.$reset();
    localStorage.clear();
    sessionStorage.clear();
    const accessTokenValue = result.data.access_token;

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessTokenValue}`;
    if (keeplogin.value) {
      setLocalStorage('accessToken', accessTokenValue);
      setLocalStorage('userId', userId.value);
      userData.userId = userId.value;
    } else {
      setStorage('accessToken', accessTokenValue);
      setStorage('userId', userId.value);
      userData.userId = userId.value;
    }
    router.push({ name: 'chatUser' });
  } catch (e) {
    console.log('error', e);
    AntNotification.error({ message: '비밀번호가 일치하지 않습니다. 입력한 내용을 다시 확인해 주세요.' });
  } finally {
    isLoggingIn.value = false;
  }
};

</script>

<style lang="scss" scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.signin_image {
  width: 200px;
}

.signin_title {
  margin-top: 25px;
  font-size: 30px;
  text-align: center;
}

.signin_subtitle {
  margin-top: 20px;
  margin-bottom: 40px;
  color: #8E8E8E;
  text-align: center;

}

.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 30px;
  margin-top: 20px;
  color: white;
  cursor: pointer;
  background-color: #1F89F6;
  border-radius: 46px;
}

.wrap_next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.signin_input {
  width: 240px;
  padding: 8px;
  margin-left: 20px;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
}

.wrap_signin_input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 80px;
  background: #F2F2F2;
  border-radius: 12px;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #B7B7B7;
}

.wrap_string {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_up {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 12px;
  color: #b9b9b9;
  cursor: pointer;
}
</style>
