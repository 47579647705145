<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div>
        받은 문의
      </div>
    </div>
    <div class="wrap_right_layout">
      <div class="wrap_table">
        <table style="table-layout: fixed">
          <tr>
            <th style="width: 50px;">
              번호
            </th>
            <th style="width: 100px;">
              이름
            </th>
            <th style="width: 200px; text-align: left;">
              이메일
            </th>
            <th style="width: 600px; text-align: left;">
              내용
            </th>
            <th style="width: 50px;">
              삭제
            </th>
          </tr>
          <tr
            v-for="(board) in boardList"
            :key="board.id"
          >
            <td style="width: 50px;">
              {{ board.id }}
            </td>
            <td style="width: 100px;">
              {{ board.name }}
            </td>
            <td style="width: 200px; text-align: left;">
              {{ board.email }}
            </td>
            <td style="width: 600px; text-align: left;">
              {{ board.message }}
            </td>
            <td class="delete">
              <span
                class="button_red"
                @click="deleteAsk(board.id)"
              >✕</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import type { SelectProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import {
  iAvatar,
  iUserRoom,
} from '@interface/chat';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { pushPage } from '@utils/route.helper';
import { routeHelper } from '@utils';
import router from '@router';
import { errorType } from '@interface/error';
import {
  postAvatar,
  requestGetAvatarList,
  getAvatarDetail,
  deleteBoard,
  getBoardList,
} from '@apis/voicechat';

const { pushQueryPage } = routeHelper;
const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

// 공지 리스트 조회 table
const boardList = ref<any[]>([]);
const getList = async () => {
  try {
    const result = await getBoardList();
    console.log(result.data, 'asdasdjlkasjdlkasjdlksajd');
    boardList.value = result.data.boards;

    // meta.value = result.data.data.meta;
  } catch (error) {
    AntNotification.error({ message: error?.message });
  }
};

const deleteAsk = (id: number) => {
  Modal.confirm({
    title: '문의 삭제',
    content: '해당 문의를 삭제하시겠습니까?',
    okText: '확인',
    okType: 'danger',
    cancelText: '취소',
    async onOk() {
      console.log(id);
      await deleteBoard(id);
      router.go(0);
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

onMounted(async () => {
  await getList();
});
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 0 0px 20px;
  cursor: pointer;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.scroll {
  height: 90vh;
  overflow-y: scroll;
}

.contact_form {
  max-width: 400px;
  padding: 20px;
  margin: 50px auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
}

textarea {
  height: 100px;
}

button {
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
  background-color: #4CAF50;
  border: none;
  border-radius: 3px;
}

button:hover {
  background-color: #45a049;
}

.button_red {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
  background: #f46a64;
  border-radius: 0.25rem;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .wrap_table {
    display: grid;
    place-items: center;
    width: 700px;
    margin-left: 50px;
  }

  table {
    width: 100%;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .wrap_table {
    display: grid;
    place-items: center;
    margin-top: 100px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
