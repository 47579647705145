import { defineStore } from 'pinia';

// 회원 정보
export const userStore = defineStore('user', {
  state: () => ({
    userId: '',
    userRole: '',
  }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

// 게스트 음성 정보
export const guestStore = defineStore('guest', {
  state: () => ({ guestString: '' }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

// 페이지 탭 정보
export const tabStore = defineStore('tab', {
  state: () => ({
    tabName: 'users',
    sideMenu: false,
  }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

// 튜토리얼
export const tutorialStore = defineStore('tutorial', {
  state: () => ({ isAvatarTutorial: true }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

// 현재 섹션, 페이지 정보
export const pageStore = defineStore('page', {
  state: () => ({
    current: 1,
    pageSize: 10,
    section: 0,
    loading: false,
  }),
  persist: { storage: sessionStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});
