<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div class="other_profile">
        아바타 페르소나 생성
      </div>
    </div>
    <div class="scroll">
      <div class="wrap_chat_screen">
        <div>
          <div class="persona-form">
            <div class="form-group">
              <label for="world">살고 있는 세계</label>
              <input
                v-model="world"
                placeholder="Enter World"
              >
            </div>

            <div class="form-group">
              <label for="era">시대</label>
              <input
                v-model="era"
                placeholder="Enter Era"
              >
            </div>

            <div class="form-group">
              <label for="place">장소</label>
              <input
                v-model="place"
                placeholder="Enter Place"
              >
            </div>

            <div class="form-group">
              <label for="happenings">해프닝</label>
              <input
                v-model="happenings"
                placeholder="Enter Happenings"
              >
            </div>

            <div class="form-group">
              <label for="name">이름</label>
              <input
                v-model="name"
                placeholder="Enter Name"
              >
            </div>

            <div class="form-group">
              <label for="age">나이</label>
              <input
                v-model="age"
                placeholder="Enter Age"
              >
            </div>

            <div class="form-group">
              <label for="tribe">종족</label>
              <input
                v-model="tribe"
                placeholder="Enter Tribe"
              >
            </div>

            <div class="form-group">
              <label for="gender">성별</label>
              <input
                v-model="gender"
                placeholder="Enter Gender"
              >
            </div>

            <div class="form-group">
              <label for="work">하는 일</label>
              <input
                v-model="work"
                placeholder="Enter Work"
              >
            </div>

            <div class="form-group">
              <label for="goal">인생목표</label>
              <input
                v-model="goal"
                placeholder="Enter Goal"
              >
            </div>

            <div class="form-group">
              <label for="value">가치관</label>
              <input
                v-model="value"
                placeholder="Enter Value"
              >
            </div>

            <div class="form-group">
              <label for="motivation">동기부여</label>
              <input
                v-model="motivation"
                placeholder="Enter Motivation"
              >
            </div>

            <div class="form-group">
              <label for="personality">성격</label>
              <input
                v-model="personality"
                placeholder="Enter Personality"
              >
            </div>

            <div class="form-group">
              <label for="mbti">MBTI</label>
              <input
                v-model="mbti"
                placeholder="Enter MBTI"
              >
            </div>

            <div class="form-group">
              <label for="favorite">좋아하는 것</label>
              <input
                v-model="favorite"
                placeholder="Enter Favorite"
              >
            </div>

            <div class="form-group">
              <label for="specialty">특기</label>
              <input
                v-model="specialty"
                placeholder="Enter Specialty"
              >
            </div>

            <div class="form-group">
              <label for="topic">주제</label>
              <input
                v-model="topic"
                placeholder="Enter Topic"
              >
            </div>

            <div class="form-group">
              <label for="speaking_habits">말하는 습관</label>
              <input
                v-model="speakingHabits"
                placeholder="Enter Speaking Habits"
              >
            </div>
            <div class="wrap_button">
              <div
                class="button_okay"
                @click="postAvatarInfo"
              >
                생성하기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { pushPage } from '@utils/route.helper';
import { errorType } from '@interface/error';
import {
  postAvatar,
  getAvatarList,
} from '@apis/voicechat';

const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

const personaMessages = ref([]);
const messageInput = ref('');
const isListening = ref(false);
const message = ref('');
const world = ref('우주 외계 행성');
const era = ref('미래');
const place = ref('지구');
const happenings = ref('오염과 자연 파괴');
const name = ref('마징가');
const age = ref('30');
const tribe = ref('로봇');
const gender = ref('남자');
const work = ref('정착');
const goal = ref('평화로운 삶');
const value = ref('자연');
const motivation = ref('자연보호');
const personality = ref('평화롭고 자상하며 이야기를 잘 들어주는');
const mbti = ref('INTJ');
const favorite = ref('자연의 동물과 식물');
const specialty = ref('산책, 그림 그리기');
const topic = ref('자연보호');
const speakingHabits = ref('"징가~"');

const postAvatarInfo = async () => {
  const params = {
    world: world.value,
    era: era.value,
    place: place.value,
    happenings: happenings.value,
    name: name.value,
    age: age.value,
    tribe: tribe.value,
    gender: gender.value,
    work: work.value,
    goal: goal.value,
    value: value.value,
    motivation: motivation.value,
    personality: personality.value,
    mbti: mbti.value,
    favorite: favorite.value,
    specialty: specialty.value,
    topic: topic.value,
    speaking_habits: speakingHabits.value,
  };
  try {
    const result = await postAvatar(params);
    console.log(result);
    // AntNotification.success({ message: '상품을 등록했습니다.' });
    // entryList.value = result.data.data;
    // await getNoriceInfo();
  } catch (error) {
    // AntNotification.error({ message: error?.message });
  }
};
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 5px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
}

.room_info {
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.295);
  border-radius: 20px;
}

.bubble {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: #F7F8FB;
  border-radius: 10px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.input_chat {
  background: white;
}

.scroll {
  height: 95vh;
  overflow-y: scroll;
}

.input {
  width: 700px;
  height: 50px;
  max-height: 245px;
  padding: 15px;
  margin-right: 10px;
  font-size: 18px;
  resize: none;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.5rem;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.json-block {
  white-space: pre-wrap;
}

.toggle-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.back {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #FF5198;
  cursor: pointer;
}

.codeblock {
  width: 100%;
  min-width: 700px;
}

.persona-form {
  width: 510px;
  padding: 20px;
  margin: 50px auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border: 1px solid #DADCE0;
  border-radius: 10px;
}

label {
  display: block;
  width: 200px;
  margin-bottom: 5px;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}
</style>
