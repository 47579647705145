export const home = '/';
export const layoutExample = '/layoutExample';
export const mainlayout = '/mainlayout';
export const login = '/login';
export const main = '/main';
export const defaultLayout = '/defaultLayout';
export const mainMobile = '/mainMobile';
export const mainDesktop = '/mainDesktop';
export const userDetail = '/userDetail';
export const landingPage = '/landingPage';
export const niceAuth = '/niceAuth';
export const chatAvatar = '/chatAvatar';
export const chatUser = '/chatUser';
export const signUp = '/signUp';
export const customVoiceLearning = '/customVoiceLearning';
export const personaChat = '/personaChat';
export const personaCopy = '/personaCopy';
export const personaEdit = '/personaEdit';
export const customVoiceRead = '/customVoiceRead';
export const customVoiceChat = '/customVoiceChat';
export const askAdmin = '/askAdmin';
export const askBoard = '/askBoard';
export const myInfo = '/myInfo';

export default {
  home,
  askBoard,
  myInfo,
  login,
  layoutExample,
  mainlayout,
  main,
  defaultLayout,
  mainMobile,
  mainDesktop,
  userDetail,
  landingPage,
  niceAuth,
  chatAvatar,
  signUp,
  chatUser,
  customVoiceLearning,
  askAdmin,
  personaChat,
  personaCopy,
  personaEdit,
  customVoiceRead,
  customVoiceChat,
};
