<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div class="other_profile">
        아바타 커스텀보이스
      </div>
    </div>
    <div class="scroll">
      <div class="wrap_chat_screen">
        <div class="label">
          <div>학습을 진행할 커스텀 보이스 wav 파일을 선택해 주세요.</div>
        </div>
        <div class="setting_box">
          <font-awesome-icon
            icon="fa-solid fa-cloud-arrow-up"
          />
          <div
            class="drag_drop"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleDrop"
          >
            파일을 선택하세요.
          </div>
          <input
            ref="fileInput"
            type="file"
            accept=".wav"
            hidden
            @change="handleFileChange"
          >
          <div
            id="browseButton"
            class="modal_cancel"
            @click="triggerFileInput"
          >
            파일 업로드하기
          </div>
        </div>
        <div class="setting_box">
          <font-awesome-icon
            icon="fa-solid fa-file"
          />
          <div class="drag_drop">
            <div>{{ selectedFile?.name }}</div>
          </div>
          <font-awesome-icon
            class="button_close"
            icon="fa-solid fa-xmark"
            @click="clearFile"
          />
        </div>
        <div class="setting_box green">
          <div class="form-group">
            <label>아바타를 선택하세요</label>
          </div>
          <div class="drag_drop">
            <a-select
              ref="select"
              v-model:value="persona"
              class="dropdown"
              :options="personaList"
              @change="selectAvatar"
            />
          </div>
          <div
            id="browseButton"
            class="button_okay"
            @click="speakerSave"
          >
            커스텀보이스 저장
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  ref,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import { SelectProps } from 'ant-design-vue';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import {
  postSpeakerAvatar,
  requestGetAvatarList,
} from '@apis/voicechat';

const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};
const selectedAvatarId = ref(0);
const selectedFile = ref<File | null>(null);
const fileBase64String = ref<string | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);

const convertToBase64 = (file: File) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    // The result attribute contains the data as a base64 encoded string
    const base64String = reader.result as string;
    // eslint-disable-next-line
    fileBase64String.value = base64String.split(',')[1];

    console.log('audio base64', fileBase64String.value);
  };
  reader.readAsDataURL(file); // Read the file as a Data URL (Base64)
};
const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  console.log('drag over');
  if (event.dataTransfer) {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }
};
const handleDrop = (event: DragEvent) => {
  event.preventDefault();
  if (event.dataTransfer && event.dataTransfer.files.length) {
    const file = event.dataTransfer.files[0];
    if (file.type === 'audio/wav') {
      selectedFile.value = file;
      convertToBase64(file);
    } else {
      alert('Please select a valid .wav file.');
      selectedFile.value = null;
    }
  }
};
const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
    const file = target.files[0];
    selectedFile.value = file;
    convertToBase64(file);
  }
};
const triggerFileInput = () => {
  selectedFile.value = null;
  fileInput.value?.click();
};
const clearFile = () => {
  selectedFile.value = null;
};
const persona = ref('');
const personaList = ref<SelectProps['options']>([{
  value: '',
  label: '--- 선택 ---',
}]);

const getAvatar = async () => {
  try {
    const result = await requestGetAvatarList(); // Corrected function name
    console.log(result.data.avatars, '💖💖');
    result.data.avatars.forEach((res: any) => {
      const temp = {
        value: res.id,
        label: res.name,
      };
      personaList.value!.push(temp);
    });
  } catch (error: any) {
    console.log(error.message);
  }
};

const selectAvatar = (avatarId: number) => {
  console.log('Selected Avatar ID:', avatarId);
  selectedAvatarId.value = avatarId;
};

const speakerSave = async () => {
  if (selectedAvatarId.value === 0) return;
  if (fileBase64String.value === '') return;
  const params = {
    audio_base64_string: fileBase64String.value,
    avatar_id: selectedAvatarId.value,
  };
  try {
    const result = await postSpeakerAvatar(params);
    AntNotification.error({ message: '성공적으로 저장하였습니다.' });
    console.log('postSpeakerAvatar result:', result);
  } catch (error: any) {
    AntNotification.error({ message: '음성 정보를 저장하는데 실패했습니다.' });
    console.log(error.message);
  }
};

onMounted(getAvatar);

</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-color: #EFEFF3;
}

.tabbar {
  position: fixed;
  top: 0;
  left: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  height: 50px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #EAEAEA;
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 5px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
}

.label {
  margin-bottom: 10px;
  font-size: 12px;
  color: #6D6D71;
  text-align: left;
}

.bubble {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: #F7F8FB;
  border-radius: 10px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.input_chat {
  background: white;
}

.scroll {
  height: 90vh;
  overflow-y: scroll;
}

.input {
  width: 100%;
  max-width: 800px;
  height: 50px;
  max-height: 245px;
  padding: 15px;
  font-size: 18px;
  resize: none;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0.5rem;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.setting_box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: 50px;
  padding: 0 20px;
  margin-bottom: 15px;
  font-size: 14px;
  background-color: white;
  border-radius: 10px;
}

#cloudIcon {
  margin-right: 10px;
}

.drag_drop {
  flex-grow: 1; /* 빈 공간을 모두 차지 */
  margin-left: 10px;
}

#browseButton {
  margin-left: 10px;
  cursor: pointer;
}

.dropdown {
  width: 200px;
}

.green {
  color: #187233;
  background-color: #EBF9EF;
}

.button_close {
  cursor: pointer;
}

.button_okay {
  background-color: #2bbc57;
}
</style>
