import { AxiosResponse } from 'axios';
import { AUTHORIZATION } from '@common/token';
import { axiosTokenInstance } from './axiosInstance';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const requestPostRefreshToken = (refreshToken: string, userId: string) => {
  const res = axiosTokenInstance.post(`/auth/users/refresh/${userId}`, { refreshToken });

  return res;
};

/**
 * timestamp 검증 : Replay Attack방지를 위해서 생성된 시간부터 일정 시간이 지난 호출의 경우 요청 무효 처리(필수)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationCheckTimestamp = (timestamp: string) => {
  const time = new Date().getTime() / 1000;
  const diffHour = (time - parseInt(timestamp, 10)) / 3600;

  // ±2 시간 이내의 요청만 허용
  if (!(Math.abs(diffHour) < 2)) {
    throw new Error('request timed out');
  }
};

/**
 * hmac 검증 : 메시지의 무결성과 인증을 위해 메시지를 해싱하여 hmac값과 다를시 요청 무료화 처리(필수)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// export const validationCheckHmac = (query_string: string, hmac_cafe: string) => {
//   const secretKey = SECRET_KEY;

//   const hash = CryptoJS.HmacSHA256(query_string, secretKey);
//   const made_hamc = hash.toString(CryptoJS.enc.Base64);

//   if (hmac_cafe !== made_hamc) {
//     throw new Error('Authentication failed');
//   }
// };

export default {
  requestPostRefreshToken,
  validationCheckTimestamp,
};
