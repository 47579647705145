import { AxiosResponse } from 'axios';
import axiosInstance from '@apis/axiosInstance';

// Create Guest Speaker Handler
export const postSpeakerGuest = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/voice/speaker/guest', params);
  return res;
};

export default postSpeakerGuest;

// Guest Voice Streams
export const postStreamsGuest = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/voice/streams/guest', params);
  return res;
};

// Create Guest Speaker Handler
export const postSpeakerUser = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/voice/speaker/user', params);
  return res;
};

export const postSpeakerAvatar = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/voice/speaker/avatar', params);
  return res;
};

// User Voice Streams
export const postStreamsUser = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/voice/streams/user', params);
  return res;
};

// 유저 정보--------------------------------------------------------------------
// 회원가입
export const postSignup = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/user/sign-up', params);
  return res;
};

// 로그인
export const postLogin = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/user/log-in', params);
  return res;
};

// 로그인 한 유저의 정보
export const getSignedUserInfo = (): Promise<AxiosResponse> => {
  const res = axiosInstance.get('/user/detail');
  return res;
};

// 유저 목록
export const getUserList = (): Promise<AxiosResponse> => {
  const res = axiosInstance.get('/user/list');
  return res;
};

// 프사변경
export const putProfilePicture = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put('/user/profile', params);
  return res;
};

// 유저 닉네임변경
export const putNickName = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put('/user/nickname', params);
  return res;
};

// 페르소나--------------------------------------------------------------------
// Create Avatar
export const postAvatar = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/avatar', params);
  return res;
};

// avatar/list
export const getAvatarList = (): Promise<AxiosResponse> => {
  const res = axiosInstance.get('/avatar/list');
  return res;
};

export const requestGetAvatarList = (): Promise<AxiosResponse> => {
  const res = axiosInstance.get('/avatar/list');
  return res;
};

export const requestGetAvatarInfo = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`/avatar/detail/${id}`);
  return res;
};

export const requestPostChatWithAvatar = (userId: string, avatarId: number, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post(`/chat/${userId}/${avatarId}`, params);
  return res;
};

// avatar detail
export const getAvatarDetail = (avatarId: number): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`avatar/detail/${avatarId}`);
  return res;
};

/* Update Avatar */
export const putAvatarInfo = (id: number, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put(`/avatar/${id}`, params);
  return res;
};

// Delete Avatar Handler
export const deleteAvatar = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.delete(`/avatar/${id}`);
  return res;
};

// 관리자에게 문의하기 -----------------------------------------
// Create Ask Board Handler
export const postAsk = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/ask_board', params);
  return res;
};

export const getBoardList = (): Promise<AxiosResponse> => {
  const res = axiosInstance.get('/ask_board/list');
  return res;
};

// Get Ask Board Info Handler
export const getBoardDetail = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`/ask_board/detail/${id}`);
  return res;
};

// Delete Ask Board Handler
export const deleteBoard = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.delete(`/ask_board/${id}`);
  return res;
};
