<template>
  <div class="center-container">
    <div class="form_box">
      <label for="profilePicture">프로필 사진 선택</label>
      <ul class="image-list">
        <li
          v-for="sampleImage in sampleImages"
          :key="sampleImage.id"
        >
          <img
            :src="sampleImage.url"
            :alt="sampleImage.name"
            :class="{ selected: sampleImage.url === selectedPicture }"
            @click="selectPicture(sampleImage.url)"
          >
        </li>
      </ul>

      <label for="username">아이디</label>
      <input
        v-model="userId"
        type="text"
        name="username"
        required
      >

      <label for="nickname">닉네임</label>
      <input
        v-model="userName"
        type="text"
        name="nickname"
        required
      >

      <label for="password">비밀번호</label>
      <input
        v-model="userPwd"
        type="password"
        name="password"
        required
      >

      <div class="wrap_button">
        <div
          class="button_okay"
          @click="requestSignup"
        >
          가입하기
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { routeHelper } from '@utils';
import { postSignup } from '@apis/voicechat';

const userId = ref('');
const userPwd = ref('');
const userName = ref('');
const selectedPicture = ref<string | null>(null);
const { pushPage } = routeHelper;

// 샘플 이미지 목록
const sampleImages = [
  {
    id: 1,
    name: '사진 1',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_fox.png'),
  },
  {
    id: 2,
    name: '사진 2',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_dog.png'),
  },
  {
    id: 3,
    name: '사진 3',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_bear.png'),
  },
  {
    id: 4,
    name: '사진 4',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_unicorn.png'),
  },
  {
    id: 5,
    name: '사진 5',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_tiger.png'),
  },
  {
    id: 6,
    name: '사진 6',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_shark.png'),
  },
  {
    id: 7,
    name: '사진 7',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_koala.png'),
  },
  {
    id: 8,
    name: '사진 8',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_lion.png'),
  },
];

const clearInputs = () => {
  userId.value = '';
  userPwd.value = '';
  userName.value = '';
  selectedPicture.value = null;
};

const selectedFileName = ref('');
const selectPicture = (url: string) => {
  selectedPicture.value = url;
  const fileName = url.split('/').pop();
  const fileNameWithoutHash = fileName.replace(/\.\w+\./, '.');
  selectedFileName.value = fileNameWithoutHash;
};

const requestSignup = async () => {
  // 선택한 이미지의 URL을 회원가입 요청에 추가
  const params = {
    user_id: userId.value,
    nick_name: userName.value,
    pwd: userPwd.value,
    profile: selectedFileName.value,
  };
  try {
    await postSignup(params);
    clearInputs();
    pushPage('login');
  } catch (error) {
    alert(`가입 실패: ${error.message}`);
  }
};
</script>

<style lang="scss" scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

form {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.image-list img.selected {
  border: 2px solid #FF5198;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.contact_form {
  width: 510px;
  padding: 20px;
  margin: 50px auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
  }

  .image-list li {
    margin-right: 10px;
  }

  .image-list img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 50%;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
  }

  .image-list li {
    margin-right: 10px;
  }

  .image-list img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
  }
}
</style>
