export { default as MainDesktop } from './MainDesktop.vue';
export { default as MainMobile } from './MainMobile.vue';
export { default as Login } from './Login.vue';
export { default as ChatAvatar } from './ChatAvatar.vue';
export { default as ChatUser } from './ChatUser.vue';
export { default as LandingPage } from './LandingPage.vue';
export { default as SignUp } from './SignUp.vue';
export { default as CustomVoiceLearning } from './CustomVoiceLearning.vue';
export { default as PersonaChat } from './PersonaChat.vue';
export { default as PersonaCopy } from './PersonaCopy.vue';
export { default as PersonaEdit } from './PersonaEdit.vue';
export { default as CustomVoiceRead } from './CustomVoiceRead.vue';
export { default as CustomVoiceChat } from './CustomVoiceChat.vue';
export { default as AskAdmin } from './AskAdmin.vue';
export { default as AskBoard } from './AskBoard.vue';
export { default as MyInfo } from './MyInfo.vue';
// export { default as MainDesktop } from './MainDesktop.vue';
