<template>
  <div class="wrap_screen">
    <div
      class="mute"
      @click="turnMute"
    >
      <img
        v-if="isMute"
        src="@assets/speaker_mute.png"
      >
      <img
        v-else
        src="@assets/speaker_on.png"
      >
    </div>
    <!-- 상단 채팅 대상-->
    <div class="tabbar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu"
      />
      <div class="other_profile">
        <div>
          <img
            class="other_picture"
            src="@assets/emoji_robot.png"
          >
        </div>
        <div class="other_profile_info">
          <div>{{ currentAvatarInfo.name }}</div>
          <!-- <div class="other_additional_info">
            additional information
          </div> -->
        </div>
      </div>
    </div>
    <div
      ref="chatScreenRef"
      class="scroll"
    >
      <div class="wrap_chat_screen">
        <div class="room_info">
          <div>{{ currentAvatarInfo.name }}와 대화를 시작해보세요</div>
        </div>
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'bubble': true,
            'other_bubble': message.role !== 'user',
            'my_bubble': message.role === 'user'
          }"
        >
          <span v-if="message.role !== 'user'">
            <div class="other_icon">
              <img src="@assets/emoji_robot.png">
            </div>
          </span>
          <span v-if="message.role === 'user'">
            <div class="my_icon">
              <img src="@assets/emoji_fox.png">
            </div>
          </span>
          <span v-if="message.role === 'assistant' && message.content === avatarLastChat">
            <AutoTyperVue
              :text="message.content"
              :repeat="false"
            />
          </span>
          <span v-else>
            {{ message.content }}
          </span>
        </div>
      </div>
    </div>
    <div class="wrap_input_screen">
      <div class="wrap_input">
        <textarea
          v-model="newMessage"
          class="input"
          placeholder="Message"
          @keypress.enter.exact.prevent="handleShiftEnterKey"
        />
        <div
          v-if="!isListening"
          class="button_rec"
          @click="startSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-microphone"
          />
        </div>
        <div
          v-if="isListening"
          class="button_rec"
          @click="stopSpeechListening()"
        >
          <font-awesome-icon
            icon="fa-solid fa-square"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import {
  ref,
  onMounted,
  computed,
  watch,
} from 'vue';
import { AutoTyperVue } from 'auto-typer-vue3';
import TypingText from '@components/TypingText.vue';
import { useSpeechRecognitionStore } from '../stores/storeSpeechRecognition';
import { useAvatarStore } from '../stores/storeAvatar';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { useAudioStore } from '../stores/storeAudio';
import { requestPostChatWithAvatar } from '@apis/voicechat';
import { postStreams } from '@apis/streams';

const route = useRoute();
const avatarId = Number(route.query.id);
const avatarStore = useAvatarStore();
const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

const chatScreenRef = ref<HTMLElement | null>(null);
const scrollToBottom = () => {
  if (chatScreenRef.value) {
    chatScreenRef.value.scrollTop = chatScreenRef.value.scrollHeight;
  }
};
const isMute = ref(false);

const turnMute = () => {
  isMute.value = !isMute.value;
};
const messages = computed(() => avatarStore.avatarMessageList[avatarStore.currentAvatarId]);
const currentAvatarId = computed(() => avatarStore.currentAvatarId);
const currentAvatarInfo = computed(() => avatarStore.currentAvatarInfo);
const currentUserId = computed(() => avatarStore.currentUserId);
const avatarLastChat = computed(() => avatarStore.avatarLastChat);
const newMessage = ref<string>('');
const isSpeaking = ref(true);
const typingLastChat = ref('');
// const getMessageContent = computed((msg: iGPTMessage) => {
//   if (msg.role == 'assistant' && msg.content == avatarStore.avatarLastChat ) {
//     const typedText = ref('');
//     let index = 0;
//     if (index < msg.content.length) {
//        typedText.value = msg.content.substring(0, index);
//        index += 1;
//        setTimeout(ViewText, 100);
//      }
//   } else {
//     return msg.content;
//   }
// });

const sendMessage = async () => {
  console.log('sending messages', newMessage.value);
  console.log('currentAvatarInfo', currentAvatarInfo.value);
  const chat = newMessage.value.trim();
  console.log('chat', chat);
  newMessage.value = '';

  if (chat !== '') {
    avatarStore.addAvatarMessage(currentAvatarId.value, {
      content: chat,
      role: 'user',
    });

    try {
      const res = await requestPostChatWithAvatar(
        currentUserId.value,
        currentAvatarId.value,
        {
          user_message: chat,
          persona: avatarStore.getPersonaFromCurrentAvatarInfo(),
        },
      );
      console.log('res [requestPostChatWithAvatar]', res);
      avatarStore.addAvatarMessage(currentAvatarId.value, {
        content: res.data.answer,
        role: 'assistant',
      });
      setTimeout(() => {
        scrollToBottom();
      }, 600);

      // Use res here
    } catch (error: any) {
      console.error('Request failed:', error);
      // Handle error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  }
};

const handleEnterKey = () => {
  sendMessage();
  newMessage.value = '';
};

const handleShiftEnterKey = (event: any) => {
  if (event.shiftKey) {
    // 쉬프트 키를 누르면서 엔터 키를 눌렀을 때
    newMessage.value += '\n'; // 또는 다른 줄바꿈 처리 방법을 사용할 수 있습니다.
  } else {
    // 쉬프트 키 없이 엔터 키만 눌렀을 때
    handleEnterKey();
  }
};

// STT
const speechRecognitionStore = useSpeechRecognitionStore();
const handleSpeechResult = (spokenText: string | null) => {
  if (spokenText) {
    newMessage.value = spokenText;
    sendMessage();
  }
};
const startSpeechListening = () => {
  speechRecognitionStore.startSpeechRecognition(handleSpeechResult);
};
const stopSpeechListening = () => {
  speechRecognitionStore.stopSpeechRecognition();
};
const isListening = computed(() => speechRecognitionStore.isListening);

// TTS
const streamAvatar = async (quest: string, id: number) => {
  const params = {
    quest,
    avatar_id: id,
  };
  try {
    const audioStore = useAudioStore();
    console.log('call streamAvatar', '🩷🩷');
    postStreams('avatar', params.quest, params.avatar_id.toString());
  } catch (error) {
    console.log(error);
  }
};

const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

watch(
  () => avatarStore.avatarLastChat,
  (newValue, oldValue) => {
    console.log('watch :', newValue, oldValue);
    // 속성 값이 변경될 때 실행되는 로직
    if (isSpeaking.value && newValue && !isMute.value) {
      console.log('[content] :', newValue, '[isSpeaking]:', isSpeaking.value);
      streamAvatar(newValue, currentAvatarId.value);
    }
    // typingLastChat.value = '';
    // const lines = newValue.split('\n');
    // for (let lineIndex = 0; lineIndex < lines.length; lineIndex += 1) {
    //   // const line = lines[index];
    //   // if (isSpeaking.value && line) {
    //   //   console.log('[content] :', line, '[isSpeaking]:', isSpeaking.value);
    //   //   streamAvatar(line, currentAvatarId.value);
    //   // }
    //   const words = lines[lineIndex].split(' ');
    //   for (let wordIndex = 0; wordIndex < words.length; wordIndex += 1) {
    //     (async () => {
    //       typingLastChat.value = `${typingLastChat.value}${words[wordIndex]} `;
    //       await sleep(50);
    //     })();
    //   }
    //   typingLastChat.value += '\n';
    // }
  },
);
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-image: url('@assets/image73.png'); /* 이미지의 경로를 실제 파일 경로로 변경하세요 */
  background-size: cover; /* 배경 이미지를 화면에 가득 채우도록 설정 */
}

.other_profile {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.other_picture {
  width: 36px;
  border-radius: 50%;
}

.other_profile_info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-size: 14px;
}

.other_additional_info {
  font-size: 14px;
  color:#999999;
}

.wrap_chat_screen {
  display: grid;
  place-items: center;
  padding-top: 100px;
}

.wrap_input_screen {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.room_info {
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.295);
  border-radius: 20px;
}

.bubble {
  display: flex;
  align-items: center;
  height: auto;
  padding: 0 20px 0 10px;
  margin-top: 10px;
  color: #3E404A;
  word-break: break-all;
  background-color: white;
  border-radius: 10px;
}

.bubble_picture {
  width: 50px;
  margin: 8px 20px;
  margin-right: 10px;
}

.other_bubble {
  margin-right: auto; /* Align to the left */
}

.my_bubble {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto; /* Align to the right */
}

.input_chat {
  background: white;
}

.scroll {
  display: flex;
  align-items: start;
  justify-content: center;
  height: 80vh;
  overflow-y: scroll;
}

.button_rec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 100%;
}

.wrap_input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #B7B7B7;
}

.my_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 12px;

  img {
    width: 40px;
    border-radius: 100%;
  }
}

.other_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 12px;

  img {
    width: 40px;
    border-radius: 100%;
  }
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .input {
    width: 700px;
    height: 50px;
    max-height: 245px;
    padding: 15px;
    margin-right: 10px;
    font-size: 18px;
    resize: none;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 0.5rem;
  }

  .tabbar {
    position: fixed;
    top: 0;
    left: 350px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: calc(100% - 350px);
    height: 50px;
    padding: 0 20px;
    background: white;
    border-bottom: 1px solid #EAEAEA;
  }

  .mute {
    position: absolute;
    top: 100px;
    right: 50px;
    cursor: pointer;
    img {
      width: 70px;
    }
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .wrap_chat_screen {
    display: grid;
    place-items: center;
    width: 45vh;
  }

  .desktop {
    display: none;
  }

  .tabbar {
    position: fixed;
    top: 0;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    background: white;
    border-bottom: 1px solid #EAEAEA;
  }

  .input {
    width: 300px;
    height: 50px;
    max-height: 245px;
    padding: 15px;
    margin-right: 10px;
    font-size: 18px;
    resize: none;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 0.5rem;
  }

  .mute {
    position: absolute;
    top: 50px;
    right: 20px;
    cursor: pointer;
    img {
      width: 50px;
    }
  }
}
</style>
