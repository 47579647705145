import { ref } from 'vue';
import { defineStore } from 'pinia';

const useSpinnerStore = defineStore('spinner', () => {
  const spinning = ref(false);
  const startSpinning = () => { spinning.value = true; };
  const endSpinning = () => { spinning.value = false; };
  return {
    spinning,
    startSpinning,
    endSpinning,
  };
});

export default useSpinnerStore;
