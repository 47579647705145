<template>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div>
        나의 커스텀보이스
      </div>
    </div>
    <div>
      <div class="wrap_chat_screen">
        <div class="form_box">
          <div class="wrap_info">
            <div>마이크 버튼을 눌러 자신의 음성을 녹음하고</div>
            <div>'커스텀보이스 등록'버튼을 눌러보세요.</div>
          </div>
          <!-- 녹음 버튼 및 녹음 중입니다. 안내-->
          <div class="wrap_rec">
            <div class="rec">
              <div
                v-if="!isListening"
                class="button_rec_mic"
                @click="startRecording()"
              >
                <font-awesome-icon
                  class="rec_icon"
                  icon="fa-solid fa-microphone"
                />
              </div>
              <div
                v-if="isListening"
                class="button_rec_stop"
                @click="stopRecording()"
              >
                <font-awesome-icon
                  class="rec_icon"
                  icon="fa-solid fa-square"
                />
              </div>
            </div>
            <div v-if="isListening">
              녹음 중입니다. 정지버튼을 눌러 녹음을 완료하세요.
            </div>
          </div>
          <!-- 녹음 확인용 오디오-->
          <div class="wrap_next">
            <div>
              <audio
                ref="audioPlayer"
                controls
              />
            </div>
          </div>
          <div>
            <textarea
              v-model="sampleText"
              class="sample_text"
            />
            <div class="wrap_next">
              <div
                class="button_okay"
                @click="streamUser()"
              >
                커스텀보이스 등록
              </div>
            </div>
            <div class="wrap_info">
              <div class="warning">
                🙏 커스텀 보이스를 등록해야 다른 사용자와 채팅 시 내 목소리를 들려줄 수 있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { useAudioStore } from '../stores/storeAudio';
import {
  postSpeakerUser,
  postStreamsUser,
} from '@apis/voicechat';
import { postStreams } from '@apis/streams';

const isListening = ref(false);
const speakerBase64Result = ref('');
let mediaRecorder: MediaRecorder | null;
let audioChunks: Blob[] = [];
const audioPlayer = ref<HTMLAudioElement | null>(null);
const message = ref('');
const sampleText = ref('동해 물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세. 무궁화 삼천리 화려 강산. 대한 사람 대한으로 길이 보전하세.');
const userData = userStore();
const tabData = tabStore();

const sendMessage = () => {
  console.log('sending messages', message);
};

const handleEnterKey = () => {
  sendMessage();
  message.value = '';
};

// speaker
const speakerUser = async (params: any) => {
  try {
    console.log(params);
    const result = await postSpeakerUser(params);
    console.log(result.data, '💛💛');
    // speakerBase64Result.value = result.data.speaker_base64_string;
  } catch (error) {
    alert(error);
  }
};

// stream
const streamUser = async () => {
  const params = {
    quest: sampleText.value,
    user_id: userData.userId,
  };
  console.log(params, 'streams/user quest, user id 일케 ');
  try {
    // const iterator = postStreams('guest', params.quest, params.speaker_base64_string);
    // let result;streamGuest
    // let i = 0;
    // do {
    //   result = iterator.next();
    //   i += 1;
    //   console.log(`iterator(${i}):`, result.value);
    // } while (!result.done);
    const audioStore = useAudioStore();
    console.log('call stream user', params.quest, userData.userId);
    postStreams('user', params.quest, userData.userId);
  } catch (error) {
    console.log(error);
  }
};

const startRecording = () => {
  isListening.value = true;

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });

        const reader = new FileReader();
        reader.onloadend = async () => {
          // base64 String
          const base64String = reader.result as string;
          const base64Data = base64String.split(',')[1];
          console.log(base64Data);

          // speaker/guest 호출
          const params = {
            audio_base64_string: base64Data,
            user_id: userData.userId,
          };
          await speakerUser(params);

          // 오디오 재생
          if (audioPlayer.value) {
            audioPlayer.value.src = base64String;
          }
        };

        reader.readAsDataURL(audioBlob);

        // 정리
        audioChunks = [];
        mediaRecorder = null;
        isListening.value = false; // 녹음이 중지될 때 isListening을 false로 설정
      };

      mediaRecorder.start();
    })
    .catch((error) => {
      console.error('마이크에 접근 중 오류 발생:', error);
      isListening.value = false;
    });
};

const stopRecording = () => {
  isListening.value = false;
  if (mediaRecorder) {
    mediaRecorder.stop();
  }
};

const showMobileMenu = () => {
  tabData.sideMenu = true;
};

</script>

<style lang="scss" scoped>

.wrap_screen {
  flex: 1;
  background-color: #EFEFF3;
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border: 1px solid #DADCE0;
  border-radius: 10px;
}

.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  color: white;
  cursor: pointer;
  background-color: #1F89F6;
  border-radius: 46px;
}

.wrap_next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.button_rec_mic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #1F89F6;
  border-radius: 100%;
}

.button_rec_stop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #FF0000;
  border-radius: 100%;
}

.rec_icon {
  color: white;
}

.wrap_rec {
  height: 100px;
  margin-bottom: 30px;
  color: #818181;
  text-align: center;
}

.rec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 15px;
}

.wrap_info {
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #818181;
  text-align: center;
  div {
    margin-bottom: 10px;
  }
}

.warning {
  font-size: 12px;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  order: -1; /* Moves the chevron to the beginning */
  color: #FF5198;
  cursor: pointer;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .form_box {
    width: 510px;
    padding: 20px;
    margin: 50px auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .wrap_chat_screen {
    display: grid;
    place-items: center;
    margin-top: 100px;
  }

  .sample_text {
    width: 430px;
    height: 150px;
    padding: 10px;
    margin: 20px;
    font-size: 12px;
    line-height: 20px;
    resize: none;
    border: 1px solid #E9E9E9;
  }

}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .form_box {
    width: 350px;
    padding: 20px;
    margin: 50px auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .wrap_chat_screen {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .sample_text {
    width: 290px;
    height: 150px;
    padding: 10px;
    margin: 10px;
    font-size: 12px;
    line-height: 20px;
    resize: none;
    border: 1px solid #E9E9E9;
  }

}
</style>
