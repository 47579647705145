<template>
  <div
    v-if="showOutModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeOutModal"
          />
        </div>
      </div>
      <div class="modal-content">
        탈퇴하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="button_okay"
          @click="deleteInfo"
        >
          탈퇴
        </div>
        <div
          class="modal_cancel"
          @click="closeOutModal"
        >
          취소
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showEditModal"
    class="modal-overlay"
  >
    <div class="modal">
      <div class="modal-header">
        <p>알림</p>
        <div class="modal_close">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            @click="closeEditModal"
          />
        </div>
      </div>
      <div class="modal-content">
        수정하시겠습니까?
      </div>
      <div class="wrap_button">
        <div
          class="button_okay"
          @click="editInfo"
        >
          수정
        </div>
        <div
          class="modal_cancel"
          @click="closeEditModal"
        >
          취소
        </div>
      </div>
    </div>
  </div>
  <div class="wrap_screen">
    <!-- 상단 채팅 대상-->
    <div class="status_bar">
      <font-awesome-icon
        class="chevron"
        icon="fa-solid fa-bars"
        @click="showMobileMenu()"
      />
      <div>
        내 정보 수정
      </div>
    </div>
    <div>
      <div class="wrap_chat_screen">
        <div class="form_box">
          <!-- 이미지 선택을 위한 이미지 리스트 -->
          <label for="profilePicture">프로필 사진 선택</label>
          <ul class="image-list">
            <li
              v-for="sampleImage in sampleImages"
              :key="sampleImage.id"
            >
              <img
                :src="sampleImage.url"
                :alt="sampleImage.name"
                :class="{ selected: sampleImage.url === selectedPicture }"
                @click="selectPicture(sampleImage.url)"
              >
            </li>
          </ul>

          <label for="username">아이디</label>
          <input
            class="disabled"
            disabled
            type="text"
            readonly
            :value="userDetail.user_id ? userDetail.user_id : ''"
          >
          <label for="nickname">닉네임</label>
          <input
            v-model="userName"
            type="text"
            name="nickname"
            required
          >
          <label for="password">변경할 비밀번호</label>
          <input
            v-model="changePassword"
            class="disabled"
            disabled
            type="password"
            name="password"
            required
          >
          <div class="wrap_button">
            <div
              class="modal_cancel"
              @click="openOutModal"
            >
              탈퇴하기
            </div>
            <div
              class="button_okay"
              @click="openEditModal"
            >
              수정하기
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';
import { notification as AntNotification } from 'ant-design-vue/lib/components';
import type { SelectProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import {
  iAvatar,
  iUserRoom,
} from '@interface/chat';
import {
  tabStore,
  userStore,
  tutorialStore,
} from '../stores/storeAuth';
import { pushPage } from '@utils/route.helper';
import router from '@router';
import { errorType } from '@interface/error';
import {
  putProfilePicture,
  getSignedUserInfo,
  putNickName,
} from '@apis/voicechat';

const userData = userStore();
const name = ref('');
const email = ref('');
const changePassword = ref('0000000');
const userName = ref('');
const showOutModal = ref(false);
const showEditModal = ref(false);
const selectedPicture = ref<string | null>(null);
const userDetail = ref<any>({});
const selectedFileName = ref('');

const tabData = tabStore();
const showMobileMenu = () => {
  tabData.sideMenu = true;
};

const getUserDetail = async () => {
  const result = await getSignedUserInfo();
  userDetail.value = result.data;
  userName.value = result.data.nick_name;
};

// 샘플 이미지 목록
const sampleImages = [
  {
    id: 1,
    name: '사진 1',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_fox.png'),
  },
  {
    id: 2,
    name: '사진 2',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_dog.png'),
  },
  {
    id: 3,
    name: '사진 3',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_bear.png'),
  },
  {
    id: 4,
    name: '사진 4',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_unicorn.png'),
  },
  {
    id: 5,
    name: '사진 5',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_tiger.png'),
  },
  {
    id: 6,
    name: '사진 6',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_shark.png'),
  },
  {
    id: 7,
    name: '사진 7',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_koala.png'),
  },
  {
    id: 8,
    name: '사진 8',
    // eslint-disable-next-line global-require
    url: require('@assets/emoji_lion.png'),
  },
];

const selectPicture = (url: string) => {
  selectedPicture.value = url;
  const fileName = url.split('/').pop();
  const fileNameWithoutHash = fileName.replace(/\.\w+\./, '.');
  selectedFileName.value = fileNameWithoutHash;
  console.log(selectedPicture.value);
};

const closeOutModal = () => {
  showOutModal.value = false;
};

const openOutModal = () => {
  showOutModal.value = true;
};

const closeEditModal = () => {
  showEditModal.value = false;
};

const openEditModal = () => {
  showEditModal.value = true;
};

const editInfo = async () => {
  const nickparam = { nick_name: userName.value };
  const profile = { profile: selectedFileName.value };
  try {
    if (selectedFileName.value !== '') {
      await putProfilePicture(profile);
    }
    await putNickName(nickparam);
    await closeEditModal();
    router.go(0);
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  await getUserDetail();
});
</script>

<style lang="scss" scoped>
.wrap_screen {
  flex: 1;
  height: 100vh;
  background-color: #EFEFF3;
}

.chevron {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #FF5198;
  cursor: pointer;
}

.wrap_chat_screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.scroll {
  height: 90vh;
  overflow-y: scroll;
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border: 1px solid #DADCE0;
  border-radius: 10px;
}

textarea {
  height: 100px;
}

button {
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
  background-color: #4CAF50;
  border: none;
  border-radius: 3px;
}

button:hover {
  background-color: #45a049;
}

.wrap_button {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.image-list img.selected {
  border: 2px solid #FF5198;
}

.disabled {
  background-color: #f3f3f3;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
  }

  .image-list li {
    margin-right: 10px;
  }

  .image-list img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 50%;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
  }

  .image-list li {
    margin-right: 10px;
  }

  .image-list img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
  }
}
</style>
