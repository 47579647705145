<template>
  <div class="wrap_screen">
    <!-- 첫 번째 화면: 데스크탑 -->
    <div
      v-if="isFirstScreen && isDesktop"
      ref="firstScreen"
      class="first-screen layout"
      @wheel="handleWheel"
    >
      <div class="center-container">
        <div class="wrap_signin">
          <div
            ref="welcomeTitle"
            class="signin_title"
          >
            어서오세요
          </div>
          <div
            ref="welcomeSubtitle"
            class="signin_subtitle"
          >
            커스텀보이스 베타버전에 오신 것을 환영합니다.
          </div>
          <div
            ref="welcomeChevron"
            class="chevron"
          >
            <img src="@assets/chevron_down.png">
          </div>
        </div>
      </div>
    </div>
    <!-- 첫 번째 화면: 모바일 -->
    <div
      v-if="isFirstScreen && !isDesktop"
      ref="firstScreen"
      class="first-screen layout"
      @wheel="handleWheel"
    >
      <div class="center-container">
        <div class="wrap_signin">
          <div
            ref="welcomeTitle"
            class="signin_title"
          >
            어서오세요
          </div>
          <div
            ref="welcomeSubtitle"
            class="signin_subtitle"
          >
            커스텀보이스 베타버전에 오신 것을 환영합니다.
          </div>
          <div class="wrap_next">
            <div
              ref="welcomeChevron"
              class="next"
              @click="goSecondScreen()"
            >
              시작하기
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 두 번째 화면 -->
    <div
      v-show="isSecondScreen"
      ref="secondScreen"
      class="second-screen"
    >
      <div class="center-container">
        <div class="wrap_rec_screen">
          <div
            class="signin_title"
          >
            버튼을 눌러 자신의 목소리를 녹음해보세요.
          </div>
          <div
            class="signin_subtitle"
          >
            약 10초 정도 녹음해주세요.
          </div>

          <!-- 녹음 버튼 및 녹음 중입니다. 안내-->
          <div class="wrap_rec">
            <div class="rec">
              <div
                v-if="!isListening"
                class="button_rec_mic"
                @click="startRecording()"
              >
                <font-awesome-icon
                  class="rec_icon"
                  icon="fa-solid fa-microphone"
                />
              </div>
              <div
                v-if="isListening"
                class="button_rec_stop"
                @click="stopRecording()"
              >
                <font-awesome-icon
                  class="rec_icon"
                  icon="fa-solid fa-square"
                />
              </div>
            </div>
            <div v-if="isListening">
              녹음 중입니다. 정지버튼을 눌러 녹음을 완료하세요.
            </div>
          </div>

          <!-- 녹음 확인용 오디오-->
          <div class="wrap_next">
            <div>
              <div>
                <audio
                  ref="audioPlayer"
                  controls
                />
              </div>
            </div>
          </div>
          <div class="wrap_next">
            <div>방금 녹음한 음성입니다. 마음에 들면 다음으로 넘어가주세요.</div>
          </div>
          <div class="wrap_next">
            <div
              class="next"
              @click="goThirdScreen"
            >
              NEXT
            </div>
          </div>
        </div>
      </div>
      <div
        class="go_signup"
        @click="pushPage('login')"
      >
        <div class="already">
          이미 계정이 있나요?
        </div>
        <div class="skip">
          건너뛰고 로그인하기 ->
        </div>
      </div>
    </div>
    <!-- 세번째 화면 -->
    <div
      v-show="isThirdScreen"
      ref="thirdScreen"
      class="third-screen"
    >
      <div class="center-container">
        <div class="wrap_signin">
          <div
            class="signin_title"
          >
            당신의 목소리로 글을 읽어드릴게요.
          </div>
          <div class="signin_title">
            <textarea
              v-model="sampleText"
              class="sample_text"
            />
          </div>
          <div class="wrap_next">
            <div
              class="next"
              @click="streamGuest()"
            >
              들어보기
            </div>
          </div>
        </div>
      </div>
      <div
        class="go_signup"
        @click="goSignUp()"
      >
        <div class="already">
          마음에 드셨나요?
        </div>
        <div class="skip">
          회원가입하고 보이스채팅 해보기 ->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
} from 'vue';
import { guestStore } from '../stores/storeAuth';
import { useAudioStore } from '../stores/storeAudio';
import { routeHelper } from '@utils';
import { postSpeakerGuest } from '@apis/voicechat';
// import { postVoiceStreams } from '@apis/voiceStreams';
import { postStreams } from '@apis/streams';
// import { postStreamsGuest } from '@apis/streams';

const { pushPage } = routeHelper;
const welcomeTitle = ref<HTMLElement | null>(null);
const welcomeSubtitle = ref<HTMLElement | null>(null);
const welcomeChevron = ref<HTMLElement | null>(null);
const firstScreen = ref<HTMLElement | null>(null);
const secondScreen = ref<HTMLElement | null>(null);
const thirdScreen = ref<HTMLElement | null>(null);
const isFirstScreen = ref(true);
const isSecondScreen = ref(true);
const isThirdScreen = ref(false);
const guestData = guestStore();
const myVoice = ref(false);
const isListening = ref(false);
const guestString = ref('');
const audioPlayer = ref<HTMLAudioElement | null>(null);

const sampleText = ref('이 모든 구성이 단 삼만구천구백원! 파격적인 혜택에 사은품까지 같이 드리니까요, 얼른 전화 주세요!');
const speakerBase64Result = ref('');
let mediaRecorder: MediaRecorder | null;
let audioChunks: Blob[] = [];

// speaker guest
const speakerGuest = async (base64Data: string) => {
  const params = { audio_base64_string: base64Data };
  try {
    const result = await postSpeakerGuest(params);
    console.log(result.data.speaker_base64_string, '💛💛');
    speakerBase64Result.value = result.data.speaker_base64_string;
  } catch (error) {
    alert(error);
  }
};

// stream guest
const streamGuest = async () => {
  if (!speakerBase64Result.value) return;
  const params = {
    quest: sampleText.value,
    speaker_base64_string: speakerBase64Result.value,
  };
  try {
    // const iterator = postStreams('guest', params.quest, params.speaker_base64_string);
    // let result;
    // let i = 0;
    // do {
    //   result = iterator.next();
    //   i += 1;
    //   console.log(`iterator(${i}):`, result.value);
    // } while (!result.done);
    postStreams('guest', params.quest, params.speaker_base64_string);
  } catch (error) {
    console.log(error);
  }
};

const startRecording = () => {
  isListening.value = true;

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });

        const reader = new FileReader();
        reader.onloadend = async () => {
          // base64 String
          const base64String = reader.result as string;
          const base64Data = base64String.split(',')[1];
          guestString.value = base64Data;
          console.log(base64Data);

          // speaker/guest 호출
          await speakerGuest(base64Data);

          // 오디오 재생
          if (audioPlayer.value) {
            audioPlayer.value.src = base64String;
          }
        };

        reader.readAsDataURL(audioBlob);

        // 정리
        audioChunks = [];
        mediaRecorder = null;
        isListening.value = false; // 녹음이 중지될 때 isListening을 false로 설정
      };

      mediaRecorder.start();
    })
    .catch((error) => {
      console.error('마이크에 접근 중 오류 발생:', error);
      isListening.value = false;
    });
};

const stopRecording = () => {
  isListening.value = false;
  myVoice.value = true;
  if (mediaRecorder) {
    mediaRecorder.stop();
  }
};

const goSignUp = () => {
  pushPage('login');
  guestData.guestString = guestString.value;
};

// ANIMATIONS
const fadeInWelcome = () => {
  // 어서오세요 메시지 페이드 인 애니메이션
  if (welcomeTitle.value) {
    welcomeTitle.value.style.opacity = '1';
  }

  // 1초 후에 서브타이틀 페이드 인 애니메이션
  setTimeout(() => {
    if (welcomeSubtitle.value) {
      welcomeSubtitle.value.style.opacity = '1';
    }
  }, 1000);

  setTimeout(() => {
    if (welcomeChevron.value) {
      welcomeChevron.value.style.opacity = '1';
      welcomeChevron.value.style.transform = 'translateY(0)';
    }
  }, 2000);
};

const goThirdScreen = () => {
  isSecondScreen.value = false;
  setTimeout(() => {
    if (thirdScreen.value) {
      thirdScreen.value.style.opacity = '1';
      thirdScreen.value.style.transform = 'translateY(0px)';
    }
  }, 1000);
  isThirdScreen.value = true;
};

const goSecondScreen = () => {
  isFirstScreen.value = false;
  setTimeout(() => {
    if (secondScreen.value) {
      secondScreen.value.style.opacity = '1';
      secondScreen.value.style.transform = 'translateY(0px)';
    }
  }, 1000);
  isThirdScreen.value = true;
};

const handleWheel = (event: WheelEvent) => {
  // 마우스 휠 이벤트에서 deltaY 값을 확인하여 스크롤 방향을 감지합니다.
  if (event.deltaY > 0) {
    console.log('스크롤 다운 감지!');
    const handleTransitionEnd = () => {
      firstScreen.value?.removeEventListener('transitionend', handleTransitionEnd);

      isFirstScreen.value = false;
    };

    setTimeout(() => {
      if (firstScreen.value) {
        firstScreen.value.addEventListener('transitionend', handleTransitionEnd);

        // Start the animation
        firstScreen.value.style.opacity = '0';
        firstScreen.value.style.transform = 'translateY(0)';
      }
    }, 1);

    setTimeout(() => {
      isSecondScreen.value = true;
      if (secondScreen.value) {
        secondScreen.value.style.opacity = '1';
      }
    }, 1000);
  }
};

const isDesktop = ref(window.innerWidth >= 769);

// 창 크기가 변할 때마다 체크
window.addEventListener('resize', () => {
  isDesktop.value = window.innerWidth >= 769;
});

onMounted(() => {
  if (welcomeTitle.value) {
    welcomeTitle.value.style.opacity = '0';
  }
  if (welcomeSubtitle.value) {
    welcomeSubtitle.value.style.opacity = '0';
  }
  if (welcomeChevron.value) {
    welcomeChevron.value.style.opacity = '0';
    welcomeChevron.value.style.transform = 'translateY(-100px)';
  }
  if (secondScreen.value) {
    secondScreen.value.style.opacity = '0';
    secondScreen.value.style.transform = 'translateY(0px)';
  }
  if (thirdScreen.value) {
    thirdScreen.value.style.opacity = '0';
    thirdScreen.value.style.transform = 'translateY(0px)';
  }

  isSecondScreen.value = true;
  isThirdScreen.value = false;

  // 컴포넌트가 마운트된 후 0.5초 뒤에 애니메이션 시작
  setTimeout(() => {
    fadeInWelcome();
  }, 500);
});
</script>

<style lang="scss" scoped>
.signin_subtitle {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 20px;
  text-align: center;
  transition: opacity 1s ease-in;
}

.chevron {
  position: absolute;
  bottom: 30px;
  left: 48.5%;
  cursor: pointer;
  transition: opacity 1s ease-in, transform 1s ease-in;
  transform: translateX(-50%);
}

.first-screen {
  transition: top 1s ease-in, opacity 1s ease-in;
}

.second-screen {
  transition: top 1s ease-in, opacity 1s ease-in;
}

.third-screen {
  opacity: 0;  // Add this line
  transition: top 1s ease-in, opacity 1s ease-in;
}

.button_rec_mic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #1F89F6;
  border-radius: 100%;
}

.button_rec_stop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #FF0000;
  border-radius: 100%;

  &.wave {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      content: "";
      border: 2px solid #1F89F6;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: waveAnimation 1.5s infinite ease-in-out;
    }
  }
}

@keyframes waveAnimation {
  0% {
    width: 50px;
    height: 50px;
    opacity: 0;
  }
  50% {
    width: 70px;
    height: 70px;
    opacity: 0.5;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

.rec_icon {
  color: white;
}

.wrap_rec {
  height: 100px;
  margin-bottom: 100px;
  color: #818181;
  text-align: center;
}

.rec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.already {
  margin-bottom: 20px;
  font-size: 16px;
  color: #818181;
}

.skip {
  font-size: 20px;
}

.wrap_screen {
  overflow: hidden;
}

.wrap_screen::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  margin-top: 20px;
  color: white;
  cursor: pointer;
  background-color: #1F89F6;
  border-radius: 46px;
}

.wrap_next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.sample_text {
  width: 400px;
  height: 150px;
  padding: 10px;
  font-size: 12px;
  line-height: 20px;
  resize: none;
  border: 1px solid #E9E9E9;
}

/* 데스크톱 스타일 */
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .signin_title {
    margin-top: 25px;
    font-size: 35px;
    text-align: center;
    transition: opacity 1s ease-in;
  }

  .go_signup {
    position: absolute;
    right: 200px;
    bottom: 150px;
    text-align: right;
    cursor: pointer;
  }

}

/* 모바일 스타일 */
@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .signin_title {
    margin-top: 25px;
    font-size: 23px;
    text-align: center;
    transition: opacity 1s ease-in;
  }

  .wrap_rec_screen {
    height: 90vh;
  }

  .go_signup {
    position: absolute;
    right: 10vw;
    bottom: 20vh;
    text-align: right;
    cursor: pointer;
  }

}
</style>
