import { createApp } from 'vue';
import PersistedState from 'pinia-plugin-persistedstate';
import { createPinia } from 'pinia';
import Antd from 'ant-design-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCoins,
  faPenToSquare,
  faUser,
  faChevronRight,
  faChevronLeft,
  faRightFromBracket,
  faSortDown,
  faVolumeHigh,
  faMagnifyingGlass,
  faStore,
  faMapLocation,
  faSliders,
  faFileSignature,
  faShirt,
  faUtensils,
  faPlus,
  faHome,
  faBars,
  faFilter,
  faTrashAlt,
  faMicrophone,
  faSquare,
  faArrowUpRightFromSquare,
  faCheck,
  faHouse,
  faLock,
  faFile,
  faXmark,
  faCloudArrowUp,
  faCircleQuestion,
  faArrowRightFromBracket,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import router from './router';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue';
import i18n from '@i18n';

const KAKAO_HTML_PATH = '/path/to/containers/Kakao.html';
const pinia = createPinia();
pinia.use(PersistedState);
// eslint-disable-next-line max-len
library.add(faPen, faArrowRightFromBracket, faCircleQuestion, faHouse, faCloudArrowUp, faXmark, faSliders, faFile, faSquare, faBars, faMicrophone, faChevronRight, faChevronLeft, faFile, faLock, faCoins, faPenToSquare, faUser, faFilter, faSortDown, faRightFromBracket, faVolumeHigh, faMagnifyingGlass, faUtensils, faStore, faShirt, faMapLocation, faFileSignature, faPlus, faHome, faTrashAlt, faArrowUpRightFromSquare, faCheck);

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(createPinia())
  .use(Antd)
  .use(pinia)
  .use(router)
  .use(i18n)
  .mount('#app');
