import { AxiosResponse } from 'axios';
import axiosInstance from '@apis/axiosInstance';

/* 매니저 로그인 */
export const loginManager = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('auth/manager/signin', params);
  return res;
};

/* 회원 상세조회 */
export const getUserInfo = (userId: string): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`auth/users/${userId}`);
  return res;
};

/* 매니저 추가 : 매니저로 로그인 상태에서 다른 매니저 추가 가능 */
export const managerSignup = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('auth/manager/signup', params);
  return res;
};

/* 매니저 추가 : 매니저로 로그인 상태에서 다른 매니저 추가 가능 */
export const postUserSignout = (userId: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post(`auth/users/signout/${userId}`);
  return res;
};

/* 패스워드 인증번호 발행 */
export const postForgotPassword = (userId: string, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post(`auth/password/confirmcode/${userId}`, params);
  return res;
};

/* 인증번호를 이용한 패스워드 변경 */
export const putChangePassword = (userId: string, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put(`auth/password/confirmcode/${userId}`, params);
  return res;
};

/* 인증번호가 맞는지 검증용 */
export const patchIsRightCode = (userId: string, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.patch(`auth/password/confirmcode/${userId}`, params);
  return res;
};
