// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStorage = (findKey: string) => localStorage.getItem(findKey);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getLocalStorage = (findKey: string) => localStorage.getItem(findKey);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export default {
  getStorage,
  setStorage,
  deleteStorage,
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
};
