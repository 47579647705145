import router from '@router';

const getRemovedSlashPath = (path: string) => path.replace('/', '');

export const getRouteName = (path: string): string => {
  if (path === '/') {
    const homeName = 'home';
    return homeName;
  }
  return getRemovedSlashPath(path);
};

export const pushPage = (routePath: string): void => {
  window.scrollTo(0, 0);
  router.push({ name: getRouteName(routePath) });
};

export const pushQueryPage = (routePath: string, id: string): void => {
  window.scrollTo(0, 0);
  router.push({
    name: getRouteName(routePath),
    query: { id },
  });
};

export const pushNoticePage = (routePath: string, noticeId: number): void => {
  router.push({
    name: getRouteName(routePath),
    query: { noticeId },
  });
};

export const pushMemberPage = (routePath: string, no: string): void => {
  router.push({
    name: getRouteName(routePath),
    query: { no },
  });
};

export const replacePage = (routePath: string): void => {
  router.replace(getRouteName(routePath));
};

export default {
  getRouteName,
  pushPage,
  pushQueryPage,
  pushMemberPage,
  pushNoticePage,
  replacePage,
};
