import {
  ref,
  Ref,
} from 'vue';
import { defineStore } from 'pinia';

// eslint-disable-next-line
export const useAudioStore = defineStore('audio', () => {
  const audioQueue: Ref<ArrayBuffer[]> = ref([]);
  const audioContext = new AudioContext();
  const currentAudio = ref();

  const clearAudioQueue = () => {
    audioQueue.value = [];
  };

  const playNextAudio = () => {
    if (audioQueue.value.length > 0) {
      const nextAudioData = audioQueue.value.shift();
      if (nextAudioData !== undefined) {
        // eslint-disable-next-line
        playAudio(nextAudioData);
      }
    }
  };

  const playAudio = async (audioBuffer: ArrayBuffer) => {
    const buffer = await audioContext.decodeAudioData(audioBuffer);
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0);
    currentAudio.value = source;
    source.onended = () => {
      currentAudio.value = null;
      playNextAudio();
    };
  };

  const addAudioDataFromBase64Chunk = (base64chunk: string) => {
    console.log('base64chunk:', base64chunk);
    const binaryData = atob(base64chunk);
    console.log('binaryData:', binaryData);

    const audioBytes = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i += 1) {
      audioBytes[i] = binaryData.charCodeAt(i);
    }
    const audioBuffer: ArrayBuffer = audioBytes.buffer;
    audioQueue.value.push(audioBuffer);
    if (!currentAudio.value) {
      playNextAudio();
    }
  };

  const pauseAudio = () => {
    if (audioContext && audioContext.state === 'running') {
      audioContext.suspend().then(() => {
        console.log('Audio paused');
      });
    }
  };

  const stopAudio = () => {
    if (audioContext) {
      audioContext.close().then(() => {
        console.log('Audio stopped and AudioContext closed');
      });
    }
  };

  return {
    clearAudioQueue,
    addAudioDataFromBase64Chunk,
    playNextAudio,
    pauseAudio,
    stopAudio,
  };
});
