import { createI18n } from 'vue-i18n';
import {
  ko,
  en,
} from '@locales';

const messages = {
  ko,
  en,
};

const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  locale: 'ko',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
